import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Menu } from 'semantic-ui-react';
import "../../../Styles/styles.css";
import BuscadorAnio from "./BuscadorAnio";
import BuscadorDocumentacion from "./BuscadorDocumentacion";

const BuscadorParametrizado = () => {
  const [selectedDependencies, setSelectedDependencies] = useState([]);
  const [selectedAños, setSelectedAños] = useState([]);
  const [text, setSearchText] = useState("");
  const [number, setNumber] = useState(undefined);
  const [from, setFrom] = useState(0);
  const [size, setSize] = useState(100);

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const text = url.searchParams.get('text');
    const yearsParam = url.searchParams.get('years');
    const dependenciesParam = url.searchParams.get('dependencies');
    const numberParam = url.searchParams.get('number');

    setSearchText(text || '');
    setSelectedAños(yearsParam ? yearsParam.split(',').map(year => ({ text: year, code: year })) : []);
    setSelectedDependencies(dependenciesParam ? dependenciesParam.split(',').map(dep => ({ name: dep, code: dep })) : []);
    setNumber(parseInt(numberParam, 10) || undefined);

  }, []);


  const handleDependencies = (data) => {
    setSelectedAños([]);
    setSelectedDependencies(data);
  };

  const handleAños = (data) => {
    setSelectedAños(data);
  };

  const enterSearch = React.useCallback((e) => {
    const currentURL = window.location.href;
    var startIndex = currentURL.indexOf('://') + 3;
    var endIndex = currentURL.indexOf('/', startIndex);
    var extractedString = currentURL.substring(startIndex, endIndex);

    const baseUrl = `http://${extractedString}/searchAdvanced/`;

    const queryParams = new URLSearchParams();

    if (from) queryParams.append('from', from);
    if (size) queryParams.append('size', size);
    if (text) queryParams.append('text', text);
    if (selectedAños?.length > 0) queryParams.append('years', selectedAños.map(year => year.code).join(','));
    if (selectedDependencies.length > 0) queryParams.append('dependencies', selectedDependencies.map(dep => dep.name).join(','));
    if (number) queryParams.append('number', number);


    window.location.href = baseUrl + '?' + queryParams.toString();
  })

  return (
    <div className="boxSearcher">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="searcher">
            <Menu>
              <Menu.Item className="searcherItem">
                <div className="col-sm-4">
                  <h4 className="mb-2 ml-2 searchTextTitles ">Documentación</h4>
                  <BuscadorDocumentacion onData={handleDependencies} />
                </div>
                <div className="col-sm-2 ">
                  <h4 className="mb-2 ml-2 searchTextTitles ">Año</h4>
                  <BuscadorAnio onData={handleAños} dependencies={selectedDependencies} />
                </div>
                <div className="col-sm-2 d-flex flex-column">
                  <h4 className="mb-2 ml-2 searchTextTitles ">Número</h4>
                  <InputText
                    className="rounded-pill w-100"
                    placeholder=""
                    type="number"
                    min={1}
                    value={number !== undefined ? number : undefined}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 d-flex flex-column">
                  <h4 className="mb-2 ml-2 searchTextTitles ">Texto de Búsqueda</h4>
                  <InputText
                    type="text"
                    className="w-100 rounded-pill"
                    placeholder=""
                    value={text}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </Menu.Item>
              <Menu.Menu position='right' className='advancedSearchButtonWrapper'>
                <Menu.Item>
                  <button className="ui button" id="searchButton" onClick={enterSearch}>Buscar</button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BuscadorParametrizado;

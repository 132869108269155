import React, { Component } from 'react';
import {
    EmailIcon,
    EmailShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import '../Styles/styles.css';


export default class ShareButtons extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const getUrl = window.location;
        const baseUrl = getUrl .protocol + "//" + getUrl.host;
        const shareUrl = baseUrl + '/searchDetail/'+this.props.doc_id;
        const title = 'Mire el siguiente documento en el centro de documentación del Consejo de la Magistratura';

        return (
            <div>
                    <div className="Demo__some-network networkPaddingLeft">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=": "
                        className="Demo__some-network__share-button"
                        onClick ={(e)=>{ e.stopPropagation()}}
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                    <div className="Demo__some-network__share-count">&nbsp;</div>
                    </div>


                    <div className="Demo__some-network">
                        <EmailShareButton
                            url={shareUrl}
                            subject={title}
                            body="body"
                            className="Demo__some-network__share-button"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>

                    <div className="Demo__some-network">
                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button"
                        onClick ={(e)=>{ e.stopPropagation()}}
                    >
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>

                    <div className="Demo__some-network__share-count">&nbsp;</div>
                    </div>

            </div>
        );
    }
}

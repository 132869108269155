
import { useMediaQuery } from 'react-responsive';
import AppBody from './AppBody';
import AppBodyMobile from './AppBodyMobile';

const AppBodyWrapper = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div>
      {isMobile ? <AppBodyMobile {...props}/> : <AppBody {...props}/>}
    </div>
  );
};

export default AppBodyWrapper;

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import React, { useEffect, useState } from 'react';

export default function DetailPdfMobile(props) {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const base64toBlob = (data) => {
      const bytes = atob(data);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: 'application/pdf' });
    };

    if (props.base64data) {
      const blob = base64toBlob(props.base64data);
      const pdfUrl = URL.createObjectURL(blob);
      if (isMounted) {
        setUrl(pdfUrl);
      }
    }

    return () => {
      isMounted = false;
      // Clean up any resources here, if needed
    };
  }, [props.base64data]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div
        style={{
          height:'450px',
          width: '350px',
        }}
      >
        {url ? <Viewer fileUrl={url} /> : 'Loading...'}
      </div>
    </Worker>
  );
}

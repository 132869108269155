import { useMediaQuery } from 'react-responsive';
import ListaResultados from './ListaResultados';
import ListaResultadosMobile from './ListaResultadosMobile';

const ListaResultadosWrapper = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div>
      {isMobile ? <ListaResultadosMobile {...props} key={window.location.pathname}/> : <ListaResultados {...props} key={window.location.pathname}/>}
    </div>
  );
};

export default ListaResultadosWrapper;
import { MultiSelect } from "primereact/multiselect";
import React, { useState } from "react";
import axiosBaseURL from "../../httpCommon";

const BuscadorAnio = (props) => {
  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  React.useEffect(() => {
    setSelectedYears(null);
    const url = new URL(window.location.href);
    const yearsParam = url.searchParams.get('years');

    // Check if the data received from the parent is what you expect
    if (props.dependencies?.length > 0) {
      const fetchYearsByDependencies = async () => {
        await axiosBaseURL.post(`/api/getaniobydependencies/`, { dependencies: props.dependencies.map(value => value.name) })
          .then(res => {
            const parsedResponseData = res?.data?.aggregations?.anio_agg?.buckets?.map(value =>
            ({
              text: value.key,
              code: value.key,
            })
            );
            setYears(parsedResponseData);
            if (yearsParam) {
              const yearsArray = yearsParam.split(',').map(year => ({
                text: year,
                code: year,
              }));
              const selectedYearsArray = yearsArray.filter(yearObj => parsedResponseData.some(year => year.text === yearObj.text));
              if (selectedYearsArray.length > 0) {
                setSelectedYears(selectedYearsArray);
                props.onData(selectedYearsArray);
              }
            }
          })
          .catch(error => {
            console.log("Failed to fetch dependencies from reduced index")
            throw error
          });
      }
      fetchYearsByDependencies();
    }

  }, [props.dependencies]);

  // Function to handle when the MultiSelect dropdown is hidden
  const handleDropdownHide = () => {
    props.onData(selectedYears);
  };

  // Function to handle onBlur
  const handleBlur = () => {
    // Check if the dropdown is currently open
    if (isDropdownOpen) {
      props.onData(selectedYears);
    }
  };

  const setMultiSelectOptions = (value) => {
    setSelectedYears(value)
    props.onData(value);
  };

  return (
    <MultiSelect
      value={selectedYears}
      onChange={(e) => setMultiSelectOptions(e.value)}
      onBlur={handleBlur}
      onHide={handleDropdownHide}
      options={years}
      optionLabel="text"
      filter
      placeholder="Seleccione Año/s"
      maxSelectedLabels={3}
      disabled={years?.length === 0 ? true : false}
      className="w-100 md:w-20rem"
    />
  );
};

export default BuscadorAnio;

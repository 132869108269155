import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { Menu, Search } from 'semantic-ui-react';
import '../../Styles/styles.css';
import Resultado from '../Resultado/Resultado';
import axiosBaseURL from '../httpCommon';

const initialState = {
  loading: false,
  results: [],
  value: '',
  open:false,
  error: '',
  baseUrl: window.location.href,
  printed:false,
  detailed:false,
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'KEEP_QUERY_CLOSE_DROPDOWN':   
      return { ...state, loading: false, open:false, error: '', baseUrl: window.location.href}
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query, open: true }
    case 'FINISH_SEARCH_SUCCESS':
      return {...state, loading: false, results: action.payload, error:''}
    case 'FINISH_SEARCH_ERROR':
      return {...state, loading: false, error:'Something went wrong', results: []}
    case 'ENTER_SEARCH':
        return {...state,loading: true, printed: false, open: false, detailed:false}
    case 'ENTER_FINISH_SEARCH':
        return {...state,loading: false, printed: true, open: false}
    case 'SELECT_RESULT':
        return {...state,loading: false, open: false, detailed: true}
    case 'CHANGE_DETAIL_DATA':
        return {...state, doc_id: action.idVal, name: action.nameVal, data: action.dataVal, tipo: action.tipoVal, dependencia: action.dependenciaVal, año: action.añoVal, description: action.descriptionVal}
    case 'RESET_PRINTED':
      return{...state, printed: false}

    default:
      throw new Error()
  }
}

function SearchExampleStandard() {

  let history = useHistory();
  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  const { loading, results, value, open} = state;

  const resultRenderer = ({fields, highlight, tipo_documento}) => <Resultado fields={fields} highlight={highlight} value={value} />

resultRenderer.propTypes = {
  fields: PropTypes.object,
  highlight: PropTypes.object,
  value: PropTypes.string
};

  const timeoutRef = React.useRef();
  const enterSearch = React.useCallback((e) => {
        //e.preventDefault();
        clearTimeout(timeoutRef.current);
        if(value !== ''){

          dispatch({ type: 'ENTER_SEARCH'});

          let url = "/searchAll/" + encodeURIComponent(value) + '/100' + '/0';

          history.push(url);

          timeoutRef.current = setTimeout(() => {
      
            dispatch({ type: 'ENTER_FINISH_SEARCH'});
            
          }, 1);
        }        
  })

  const selectResult = React.useCallback((e) => {   
    dispatch({type: 'SELECT_RESULT'});
  });

  const handleSearchChange = React.useCallback((e, data) => {

    clearTimeout(timeoutRef.current);
    dispatch({ type: 'CLEAN_QUERY' })
   
    if (data.value.length !== 0) {
      dispatch({ type: 'START_SEARCH', query: data.value });
      timeoutRef.current = setTimeout(() => {
        axiosBaseURL.get(`/api/search/`+ encodeURIComponent(data.value))
          .then(response => {
            let responseResults = response.data.hits.hits.map(h => h).sort(
              (a,b) => {return a.fields.anio[0] > b.fields.anio[0] ? -1 : 1;});
            console.log(responseResults);
            dispatch({type: 'FINISH_SEARCH_SUCCESS', payload: responseResults});
          })
          .catch(error => {
            dispatch({type: 'FINISH_SEARCH_ERROR'});
          });
        
      }, 750);  
    }

  }, []);



  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      enterSearch();
    }
  }
      
  
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  

  function useOutsideAlerter(ref) {
      useEffect(() => {
          function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                  dispatch({type: 'KEEP_QUERY_CLOSE_DROPDOWN' })               
              }
          }
          // Bind the event listener
          document.addEventListener("click", handleClickOutside);
          return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("click", handleClickOutside);
          };
      }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
           
  return (
    <div className="boxSearcher">
    <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div ref={wrapperRef} className="searcher">
            <Menu>
              <Menu.Item className="searcherItem">
                <Search
                fluid={true}
                loading={loading}
                onResultSelect={selectResult}
                open={open}
                onSearchChange={handleSearchChange}
                resultRenderer={resultRenderer}
                results={results}
                value={value}
                minCharacters={3}
                className="width100"                                
                onKeyDown={handleKeyDown}
                placeholder="Ingrese el texto que desee buscar en el contenido de cualquier DOCUMENTO que podrá compartir, descargar y visualizar. Presione ENTER o click en BUSCAR para mostrar todos los resultados."
                />
              </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item >
                <button className="ui button" id="searchButton" onClick={enterSearch}>Buscar</button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </div>
        </Col>
    </Row>
    </div>
  );
}

export default withRouter(SearchExampleStandard);




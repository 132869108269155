import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

const Back = () => {
  const history = useHistory();

  return (
    <div className="backButtonT">
      <button id="backButton" onClick={history.goBack}>
        {"<Volver"}
      </button>
    </div>
  );
};

export default Back;

import { InputSwitch as PrimeSwitch } from "primereact/inputswitch";
import { React, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Buscador2 from "../Buscador2";
import BuscadorParametrizado from "./BuscadorParametrizado";

const BuscadorAvanzado = () => {
  const [mostrarBuscadorAvanzado, setMostrarBuscadorAvanzado] = useState(false);

  const handleSwitchChange = (event) => {
    setMostrarBuscadorAvanzado(event.value);
  };

  useEffect(() => {
    const currentURL = window.location.href;
    var startIndex = currentURL.indexOf('://') + 3; 
    var endIndex = currentURL.indexOf('/', startIndex);
    var extractedString = currentURL.substring(startIndex, endIndex);
    if (currentURL.includes(`${extractedString}/searchAdvanced/`)) {
      setMostrarBuscadorAvanzado(true);
    } else {
      setMostrarBuscadorAvanzado(false);
    }
  }, []);

  return (
    <div>
      <>
        <Row>
          <Col xs={0} sm={1} md={1} lg={1}></Col>
          <Col xs={12} sm={10} md={10} lg={10} className="d-flex mt-5">
            {" "}
            <h3
              className={`mt-0 mr-3 ${
                mostrarBuscadorAvanzado ? "search-light-text" : "search-dark-text"
              } busquedaAgil`}
            >
              Búsqueda Ágil
            </h3>
            <PrimeSwitch
              checked={mostrarBuscadorAvanzado}
              onChange={handleSwitchChange}
            />
            <h3
              className={`mt-0 ml-3 ${
                mostrarBuscadorAvanzado ? "search-dark-text" : "search-light-text"
              }`}
            >
              Búsqueda Avanzada
            </h3>
          </Col>
          <Col xs={0} sm={1} md={1} lg={1}></Col>
        </Row>
        <Row>
        <Col xs={1} sm={1} md={1} lg={1}></Col>
        <Col xs={10} sm={10} md={10} lg={10} className="d-flex">
          {mostrarBuscadorAvanzado ? <BuscadorParametrizado /> : <Buscador2 />}
        </Col>
        <Col xs={1} sm={1} md={1} lg={1}></Col>
        </Row>
      </>
    </div>
  );
};

export default BuscadorAvanzado;

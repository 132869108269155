import Chip from '@material-ui/core/Chip';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import imgDic from '../../Images/TypesOfDocs/dictamenes.png';
import imgDis from '../../Images/TypesOfDocs/disposiciones.png';
import imgNoD from '../../Images/TypesOfDocs/no determinado.png';
import imgOtr from '../../Images/TypesOfDocs/otros.png';
import imgRes from '../../Images/TypesOfDocs/resoluciones.png';
import '../../Styles/styles.css';
import Buttons2 from '../Buttons2';
import ClickAndPreview2 from '../ClickAndPreview2';

export default class Resultado extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: imgDis,
            detail: false,
            id:'',
            redirect: false
        };
    }
    
    componentDidMount(){
        const imageMap = {
            "Otra documentación": imgOtr,
            "Disposición": imgDis,
            "Dictamen": imgDic,
            // Map any tipo that starts with "Resolucion" to imgRes
            ...this.props.tipo.reduce((acc, t) => t.startsWith("Resolución") ? {...acc, [t]: imgRes} : acc, {})
          }
          
          const imageUrl = imageMap[this.props.tipo[0]] || imgNoD;
          this.setState({ imageUrl });
    }
    
    /*handleDetail = () => {
        this.props.onDetailSelect(this.props.doc_id, this.props.name, this.props.base64data, this.props.tipo, this.props.dependencia, this.props.año, this.props.description);
        this.props.selectResult()
    }
    */


    handleDetail = (e) => {
        this.setState({redirect: true});
    }


    getHighlightedText = (text, highlight) => {
        // Decode the highlight term if it's URI encoded
        highlight = decodeURIComponent(highlight);

        // Escape highlight for safe use in regular expression
        const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Split text using escaped highlight as a regular expression
        const parts = text.split(new RegExp(`(${escapedHighlight.replace(/\s+/g, "\\s+")})`, 'gi'));

        return (
        <span>
            {parts.map((part, i) => (
            <span
                key={i}
                style={{
                background:
                    part.replace(/\s+/g, " ").toLowerCase() === highlight.toLowerCase()
                    ? '#e3e06f'
                    : 'transparent'
                }}
            >
                {part}
            </span>
            ))}
        </span>
        );
    }
    

    render() {
        if (this.state.redirect) {            
            let url = "/searchDetail/" + this.props.doc_id;
            this.setState({redirect: false})
            return <Redirect push to={url} />;
        }
        return (
            <div className="d-flex align-items-center fitHeight " style={{cursor:"pointer"}}>
                <div className="ContainerCustom2">
                    <div className="padding2top">
                        <hr/>
                    </div>
                    
                    <Row className="padding2right" style={{justifyContent: "space-evenly"}}>
                        <Col xs={2} md={2} sm={2} lg={2} xl={2} xxl={2}>
                           <div className="padding10left padding15top">
                                <div className="modalResultadoCompleto">
                                    <ClickAndPreview2 doc_id={this.props.doc_id}/>                                   
                                </div>
                           </div>    
                           
                        
                        </Col>  

                        <Col xs={9} md={9} sm={9} lg={9} xl={9} xxl={9}>
                            <Row className="" onClick={this.handleDetail}>
                                <div className="resultText titleB">{this.getHighlightedText(this.props.name+ '', this.props.value+ '')}</div>
                            </Row>
                            { this.props.description ? 
                                <Row className="padding15top padding5bottom titleD" onClick={this.handleDetail}>
                                    {this.props.description[0].substring(0,250)}
                                </Row> 
                                : 
                                <div/> 
                            }
                            {
                                this.props.highlight ?
                                <Row className="titleC" onClick={this.handleDetail}>
                                    <i>{this.getHighlightedText((this.props.highlight+ '').substring(0,250)+'...', this.props.value + '')}</i>
                                </Row> 
                                : 
                                <div/>
                            }
                            <Row className="">
                                <Col  xs={1} md={1} sm={1} lg={1} xl={1} xxl={1} className="padding0">
                                    <img className="resultIMG2" src={this.state.imageUrl}></img>
                                </Col> 

                                <Col xs={11} md={11} sm={11} lg={11} xl={11} xxl={11}>
                                        <div className="paddingTopButtons3 ">
                                            <div className="resultText">
                                                <div className="floatleft padding7top">
                                                    <Chip className="border-radius5" label={this.props.dependencia}/>
                                                </div>
                                                <div className="padding10left padding7top floatleft">
                                                    <Chip className="border-radius5" label={this.props.tipo}/>
                                                </div>
                                                <div className="padding10left padding7top floatleft">
                                                    <Chip className="border-radius5" label={this.props.año}/>
                                                </div>

                                                <div className="paddingLeftButtons floatleft ">
                                                    <Buttons2 name={this.props.name} doc_id={this.props.doc_id}/>
                                                </div>

                                            </div>
                                        </div>     
                                </Col>
                            </Row>
                        </Col>     
                    </Row>     
                </div>
            </div>
        );
     
    }
  
  
}


import { useMediaQuery } from 'react-responsive';
import BuscadorAvanzado from './BuscadorAvanzado/BuscadorAvanzado';
import BuscadorMobile from './BuscadorMobile';

const BuscadorWrapper = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div>
      {isMobile ? <BuscadorMobile/> : <BuscadorAvanzado />}
    </div>
  );
};

export default BuscadorWrapper;
import { Route, Switch } from "react-router-dom";
import Admin from "../Admin/Admin";
import DetalleWrapper from "../Detalle/DetalleWrapper";
import ListaResultadosWrapper from "../ListaResultados/ListaResultadosWrapper";

const AppBodyMobile = (props) => {
  return (
    <div className="resultList">
      <Switch>
        <Route
          exact
          path="/searchDetail/:id"
          component={(props) => (
            <DetalleWrapper></DetalleWrapper>
          )}
        ></Route>
        <Route
          path="/searchAll/:text/:size/:start"
          component={(props) => (
            <ListaResultadosWrapper input={props.match.params.text}/>
          )}
        ></Route>
          <Route
          path="/admin"
          render={() => {
            if (!props.isAuthenticated) {
              props.handleAdminAuthentication();
            }
            return <Admin {...props} />;
          }}
        ></Route>
      </Switch>
    </div>
  );
};

export default AppBodyMobile;

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CancelIcon from "@material-ui/icons/Cancel";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import { withRouter } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import "../../Styles/styles.css";
import ClickAndPreview2 from "../ClickAndPreview2";
import axiosBaseURL from "../httpCommon";

function SearchExampleStandard({ onData, onDataMod }) {
  const [idDocumento, setIdDocumento] = useState();
  const [nombreDocumento, setNombreDocumento] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [numero, setNumero] = useState();
  const [showNohayResultado, setShowNoHayResultado] = useState(false);
  const [load, setLoad] = useState(false);
  const tipoDocumentos = [
    { name: "Resolución Presidencia" },
    { name: "Resolución Plenario" },
  ];
  const yearOptions = [];

  for (let year = 1998; year <= 2024; year++) {
    yearOptions.push({ name: String(year), code: String(year) });
  }

  const timeoutRef = React.useRef();

  const enterSearch = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoad(true);
      const year = selectedYear.name || "";
      const tipo = selectedOption.name || "";
      const num = numero || "";

      console.log(year, tipo, num);

      const urlParams = [
        year ? `year=${year}` : "",
        tipo ? `tipo=${tipo}` : "",
        num ? `num=${num}` : "",
      ]
        .filter((param) => param !== "")
        .join("&");

      axiosBaseURL
        .get(`/api/searchtitle?${urlParams}`)
        .then((response) => {
          const responseResults = response.data.hits.hits.map((h) => h);

          if (responseResults.length > 0) {
            setShowNoHayResultado(false);
            setIdDocumento(responseResults[0].fields.doc_id);
            if (typeof onData !== "undefined") {
              onData(responseResults);
            }
            if (typeof onDataMod !== "undefined") {
              onDataMod(responseResults);
            }
            setNombreDocumento(responseResults[0].fields.name);
          } else {
            setShowNoHayResultado(true);
            setIdDocumento("");
            setNombreDocumento("");
          }

          setLoad(false);
        })
        .catch((error) => { });
    },
    [selectedYear, selectedOption, numero, onData, onDataMod]
  );

  const handleChangeNum = (e) => {
    setNumero(e.value);
  };

  const handleChangeYears = (e) => {
    setSelectedYear(e.value);
  };

  const handleChangeTipos = (e) => {
    setSelectedOption(e.value);
  };

  const wrapperRef = useRef(null);

  return (
    <Row className="mb-4 searcherRow">
      <Col xs={12} sm={12} md={12} lg={12}>
        <div>
          <Row>
            <div className="boxSearcher">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div ref={wrapperRef} className="searcher">
                    <Menu>
                      <Menu.Item>
                        <Dropdown
                          value={selectedOption}
                          onChange={handleChangeTipos}
                          options={tipoDocumentos}
                          optionLabel="name"
                          filter
                          placeholder="Seleccione El Tipo"
                          style={{ width: "200px" }}
                          className="w-full md:w-20rem"
                        />
                      </Menu.Item>

                      <Menu.Item className="">
                        <Dropdown
                          value={selectedYear}
                          onChange={handleChangeYears}
                          options={yearOptions}
                          optionLabel="name"
                          filter
                          placeholder="Año"
                          style={{ width: "150px" }}
                          className="w-full md:w-20rem"
                        />
                      </Menu.Item>

                      <Menu.Item>
                        <InputNumber
                          value={numero}
                          onChange={handleChangeNum}
                          useGrouping={false}
                          className=""
                          placeholder="Número"
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          className="ui button"
                          id="searchButton"
                          onClick={enterSearch}
                          disabled={
                            !numero &&
                            selectedYear !== "" &&
                            selectedOption !== ""
                          }
                        >
                          <FindInPageIcon
                            className="mb-1 m-0"
                            fontSize="small"
                          />
                        </button>
                      </Menu.Item>
                      {nombreDocumento && (
                        <>
                          <Menu.Item>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className="mb-2">
                                  {nombreDocumento}
                                </Tooltip>
                              }
                            >
                              <div
                                className="font-weight-bold d-flex align-items-center"
                                style={{
                                  maxWidth: "300px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <span className="text-truncate mr-1">
                                  {nombreDocumento}
                                </span>
                              </div>
                            </OverlayTrigger>
                            <AssignmentTurnedInIcon
                              className="text-success"
                              fontSize="small"
                            />
                          </Menu.Item>
                          <Menu.Item>
                            <div className="modalPreviewPDFRelacion">
                              <ClickAndPreview2 doc_id={idDocumento} />
                            </div>
                          </Menu.Item>
                        </>
                      )}

                      {showNohayResultado && (
                        <>
                          <Menu.Item>
                            <div className="font-weight-bold d-flex align-items-center">
                              No hay Resultados
                            </div>
                            <CancelIcon
                              className="text-danger"
                              fontSize="small"
                            />
                          </Menu.Item>
                        </>
                      )}

                      {load && (
                        <>
                          <Menu.Item>
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Cargando...</span>
                            </Spinner>
                          </Menu.Item>
                        </>
                      )}
                    </Menu>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default withRouter(SearchExampleStandard);

import axiosBaseURL from "../Components/httpCommon";

const getAnalisisNormativos = async () => {
  const response = await axiosBaseURL.get("/api/getanalisisnormativo");
  const filteredData = response.data.filter(
    (item) => item.docoriginal.fecha_creacion !== "sin datos"
  );
  console.log("Analisis Normativos", filteredData);
  return filteredData;
};

const getDocumentById = (id) => {
  const request = axiosBaseURL.get("/api/searchid/" + id);
  return request.then((response) => response.data._source);
};

const getPdfDocumentById = (id) => {
  const request = axiosBaseURL.get("/api/searchid/" + id);
  return request.then((response) => response.data._source.pdf_base64);
};

const removeModificatoria = (idDocOriginal, idModificatoria) => {
  const request = axiosBaseURL.post("/api/removemodificatoria", {
    sourceid: idDocOriginal,
    modificatoriaid: idModificatoria,
  });
  return request.then((response) => response.data);
};

export default {
  getPdfDocumentById,
  getDocumentById,
  getAnalisisNormativos,
  removeModificatoria,
};

import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState } from "react";
import axiosBaseURL from "../../httpCommon";

const BuscadorDocumentacion = (props) => {
  const [dependencies, setDependencies] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const setMultiSelectOptions = (value) => {
    setSelectedOptions(value)
  };

  // Function to handle when the MultiSelect dropdown is hidden
  const handleDropdownHide = () => {
    props.onData(selectedOptions);
  };

  // Function to handle onBlur
  const handleBlur = () => {
    // Check if the dropdown is currently open
    if (isDropdownOpen) {
      props.onData(selectedOptions);
    }
  };

  useEffect(()=> {
    const url = new URL(window.location.href);
    const dependenciesParam = url.searchParams.get('dependencies');

    async function fetchDependenciesReduced (){
      await axiosBaseURL.get(`/api/getdependenciesreduced/`)
      .then(res => {
        const parsedResponseData = res?.data?.aggregations?.tipo_doc_agg?.buckets?.map(value => 
          ({ 
            name: value.key,
            code: value.key,
          })
        );
        setDependencies(parsedResponseData);
        if(dependenciesParam){
          const depArray = dependenciesParam.split(',').map(dep => ({
            name: dep,
            code: dep,
          }));
          const selectedOptArray = depArray.filter(depObj => parsedResponseData.some(dep => dep.name === depObj.name));
          if (selectedOptArray.length > 0) {
            setSelectedOptions(selectedOptArray);
          }
        } 
      })
      .catch(error => {
        console.log("Failed to fetch dependencies from reduced index")
        throw error
      });
    }
    return fetchDependenciesReduced();
  }, []); 

  return (
    <MultiSelect
      value={selectedOptions}
      onChange={(e) => setMultiSelectOptions(e.value)}
      onBlur={handleBlur}
      onHide={handleDropdownHide}
      options={dependencies}
      optionLabel="name"
      filter
      placeholder="Seleccione Tipo/s"
      maxSelectedLabels={3}
      className="w-100 md:w-20rem"
    />
  );
};

export default BuscadorDocumentacion;

import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { Router, Link, Route, Switch, Redirect } from "react-router-dom";
import "./admin.css";
import ListadoAnalisisNormativos from "./ListadoAnalisisNormativos";
import Relacion from "./Relacion";
import TipoRelacion from "./TipoRelacion";

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href =
  "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

export const customHistory = createBrowserHistory();

function Admin() {
  useEffect(() => {
    document.body.classList.add("backgroundImg");
    return () => {
      document.body.classList.remove("backgroundImg");
    };
  }, []);

  return (
    <div>
      <Router history={customHistory}>
        <Row className="">
          <div className="admin-buttons py-5 pl-5">
            <Link to="/admin">
              <button className="ui button">Admin</button>
            </Link>
            <Link to="/admin/addRelacion">
              <button className="ui button">Agregar Relacion</button>
            </Link>
            {/*<Link to="/admin/addTipoRelacion">
              <button className="ui button">Agregar Tipo Relacion </button>
            </Link>*/}
          </div>
        </Row>
        <Row className="w-auto pl-5">
          <Row className="">
            <Switch>
              <Route path="/admin/addRelacion" component={Relacion} />
              <Route path="/admin/addTipoRelacion" component={TipoRelacion} />
              <Route path="/admin">
                {/* <ListadoRelaciones /> */}
                <ListadoAnalisisNormativos />
              </Route>
              <Redirect to="/admin" />
            </Switch>
          </Row>
        </Row>
      </Router>
    </div>
  );
}

export default Admin;

import { useMediaQuery } from 'react-responsive';
import Detalle from './Detalle';
import DetalleMobile from './DetalleMobile.js';

const DetalleWrapper = () => {
  const isMobile = useMediaQuery({ maxWidth: 500 });
  return (
    <div>
      {isMobile ? <DetalleMobile style={{'overflow-x': 'hidden'}} key={window.location.pathname}/> : <Detalle key={window.location.pathname} />}
    </div>
  );
};

export default DetalleWrapper;
import { useMediaQuery } from 'react-responsive';
import DetailPdf from './DetailPdf';
import DetailPdfMobile from './DetailPdfMobile.js';

const DetailPdfWrapper = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div>
      {isMobile ? <DetailPdfMobile base64data={props.base64data} /> : <DetailPdf base64data={props.base64data}/>}
    </div>
  );
};

export default DetailPdfWrapper;
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import AddTipoRelacion from './AddTipoRelacion'
import axiosBaseURL from '../httpCommon';
import Table from 'react-bootstrap/Table';
import DeleteForeverOutlinedIcon from '@material-ui/icons//DeleteForeverOutlined';

const TipoRelacion = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [formValues, setFormValues] = useState([{ relacion: '', contrarelacion:''}])
  const [id_remove, setIdRemove] = useState({ id: ''})
  const [showConfirm, setShowConfirm] = useState(false);


  useEffect(() => {
    loadRelaciones();
  }, [])

  const loadRelaciones = async () => {
      try {
        const response = await axiosBaseURL.get('/api/gettiporelaciones');
        const tipoRelacionesResult = response.data.hits.hits.map(obj => ({
          "id": obj._id,
          "relacion": obj.fields.nombre[0],
          "contrarelacion": obj.fields.inversa[0]
        }));
        setFormValues(tipoRelacionesResult);
      } catch (error) {
        console.log(error);
      }
  } 
  
  const removeTipoRelacion = (id) => {
    setShowConfirm(true);
    setIdRemove({id});
  }
  
  const handleConfirm = async () => {
    setShowConfirm(false);
    await axiosBaseURL.post(`/api/removetiporelacion`, { id: id_remove.id })
      .then(async response => {
        setTimeout(async () => {
          await loadRelaciones();
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  }
  
  const handleCancel = () => {
    setShowConfirm(false);
  }

  async function handleClose(value) { 
    setModalShow(value); 
    setTimeout(async () => {
      await loadRelaciones();
    }, 1000);
  }

  function AddTipoRelacionModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Agregar Tipo Relación
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddTipoRelacion onClose={handleClose} />
        </Modal.Body>
      </Modal>
    );
  } 

  const DeleteModal = ({ show, onConfirm, onCancel }) => {
    return (
      <Modal show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de que desea eliminar este elemento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className='m-5'>
      <h1>Tipos de Relación</h1>
      <Button variant="primary" className="addRelacionbutton" onClick={() => setModalShow(true)}>
        Agregar Tipo Relación
      </Button>
      <AddTipoRelacionModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <DeleteModal
        show={showConfirm}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>id</th>
            <th>Relación</th>
            <th>Contrarelación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {formValues.map((element, index) => (
            <tr key={index}>
              <td>{formValues[index]["id"]}</td>
              <td>{formValues[index]["relacion"]}</td>
              <td>{formValues[index]["contrarelacion"]}</td>
              <td><DeleteForeverOutlinedIcon fontSize='small' onClick={() => removeTipoRelacion(formValues[index]["id"])}/></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default TipoRelacion
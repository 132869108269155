import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import '../Styles/styles.css';
import axiosBaseURL from './httpCommon';
import Pdf from './Pdf';


function getModalStyle() {
  const top = 45;
  const left = 45;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: '97.5vh',
    width: '68vh',

  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    'border-radius':'5px',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(0, 0, 0),
    display:'inline-flexe-flex',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [b64, setFile] = React.useState("");
  const [image, setImage] = React.useState();
  
  React.useEffect(()=>{
    if(props.doc_id){   
      axiosBaseURL.get('/api/searchimg/'+props.doc_id, { responseType: 'blob' }).then(response => {
        setImage(URL.createObjectURL(response.data))
        //console.log(response)
      }).catch(error =>{
        console.log(error);
      });    
  }})

  const handleOpen = () => {
    if(props.doc_id){
      axiosBaseURL.get('/api/searchid/'+props.doc_id)
      .then(response => {
        setFile(response.data._source.pdf_base64);
        setOpen(true);
      }).catch(error =>{
        console.log(error);
      });  
    }
    if(props.base64){
      setFile(props.base64);
      setOpen(true);
    }  
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <Pdf base64data={b64}/>
    </div>
  );

  return (  
    <div>
        <div onClick={handleOpen}>
            <img src={image} className="imgsource"/>
            <div className="previewEyeIcon">
              <VisibilityIcon/>
            </div>
        </div>   
        <Modal
            open={open}
            onClose={handleClose}
        >
            {body}
        </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import axiosBaseURL from '../httpCommon';

const TipoRelacion = (props) => {
  const [formValues, setFormValues] = useState([{ relacion: '', contrarelacion:''}])

  const handleChangeRelacion = (i, e) => {
    const newFormValues = [...formValues]
    newFormValues[i].relacion = e.target.value
    setFormValues(newFormValues)
  }

  const handleChangeContrarelacion = (i, e) => {
    const newFormValues = [...formValues]
    newFormValues[i].contrarelacion = e.target.value
    setFormValues(newFormValues)
  }

  const addFormFields = (e) => {
    setFormValues([...formValues, { relacion: '' }])
  }

  const removeFormFields = (i) => {
    const newFormValues = [...formValues]
    newFormValues.splice(i, 1)
    setFormValues(newFormValues)
  }

  const closeModal = () => {
    props.onClose(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await axiosBaseURL.post(`/api/addtiporelacion`, formValues)
    .then(response => {
      props.onClose(false);
    })
    .catch(error => {
      console.log(error);
    });
  }

  return (
    <div className='m-5'>
      <Form onSubmit={handleSubmit}>

        {formValues.map((element, index) => (
          <Form.Group as={Row} className='mt-5 mb-3 d-flex align-items-center' controlId='relacion' key={index}>
            <Form.Label column sm={3}>
              Nombre Relación
            </Form.Label>
            <Col sm={4}>
              <Form.Control type='text' placeholder='Relación' value={formValues[index].relacion} onChange={e => handleChangeRelacion(index, e)} />
            </Col>
            <Col sm={4}>
              <Form.Control type='text' placeholder='Contrarelación' value={formValues[index].contrarelacion} onChange={e => handleChangeContrarelacion(index, e)} />
            </Col>
            <Col sm={1}>
            { index ? (
                <IconButton aria-label='print' size='small' onClick={() => removeFormFields(index)}>
                  <RemoveCircleOutlineIcon fontSize='small' />
                </IconButton>
             ) : null
            }
            </Col>
          </Form.Group>
        ))}

        <IconButton aria-label='print' size='small' onClick={(e) => addFormFields(e)}>
          <AddIcon fontSize='small' />
        </IconButton>
        <Form.Group as={Row} className='mb-1'>
          <Col sm={{ span: 10, offset: 0 }}>
            <button className="ui button mt-5 mr-3 saveButton" type='submit'>
              Guardar
            </button>
            <button className="ui button" onClick={closeModal}>
              Cancelar
            </button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  )
}

export default TipoRelacion

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import '../Styles/styles.css';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};


export default function Sample(props) {
  
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);

  const onDocumentLoadSuccess = useCallback((document) => {
    console.log('Loaded a document', document);
    const { numPages: nextNumPages } = document;
    setNumPages(nextNumPages);
    setPageNumber(1);
  }, []);
  

  
const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

const previousPage = useCallback((e) => changePage(-1), [changePage]);

const nextPage = useCallback((e) => changePage(1), [changePage]);

function removeTextLayerOffset() {
  const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
  textLayers.forEach((layer) => {
    const { style } = layer;
    style.display = 'none';
  });
}

/*const b64data = 'JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwog' +
            'IC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAv' +
            'TWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0K' +
            'Pj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAg' +
            'L1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+' +
            'PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9u' +
            'dAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2Jq' +
            'Cgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJU' +
            'CjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVu' +
            'ZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4g' +
            'CjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAw' +
            'MDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9v' +
            'dCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G'*/
  return (
        <div >
          <Document
            file={`data:application/pdf;base64,${props.base64data}`}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            onClick={(e)=>{ e.stopPropagation(); }}
          >
          <Page pageNumber={pageNumber}  onLoadSuccess={removeTextLayerOffset} />
            <div className="buttons">

                <Button 
                    className="glyphbutton"
                    disabled={(pageNumber <= 1)}
                    onClick={(e)=>{ e.stopPropagation(); previousPage(e)}}
                    type="button"
                  
                    >
                  <ChevronLeftIcon/>
                </Button>

            
                <span>
                  {`  ${pageNumber || (numPages ? 1 : '--')} / ${numPages || '--'}  `}
                </span>
              

                  <Button
                    className="glyphbutton"
                    disabled={(pageNumber >= numPages)}
                    onClick ={(e)=>{ e.stopPropagation(); nextPage(e)}}
                    type="button"
              
                    >
                    <ChevronRightIcon/>
                  </Button>

            </div>
          </Document>
        </div>
  );
}

/*{
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )
            }
            */
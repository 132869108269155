import Chip from "@material-ui/core/Chip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import imgDic from "../../Images/TypesOfDocs/dictamenes.png";
import imgDis from "../../Images/TypesOfDocs/disposiciones.png";
import imgNoD from "../../Images/TypesOfDocs/no determinado.png";
import imgOtr from "../../Images/TypesOfDocs/otros.png";
import imgRes from "../../Images/TypesOfDocs/resoluciones.png";
import "../../Styles/detalle.css";
import "../../Styles/styles.css";
import Back from "../Back";
import Buttons2 from "../Buttons";
import DetailPdfWrapper from "../DetailPdf/DetailPdfWrapper";
import axiosBaseURL from "../httpCommon";

const DetalleMobile = (props) => {
  const [data, setData] = useState([]);
  const [dataTextoVigente, setDataTextoVigente] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    axiosBaseURL
      .get("/api/searchid/" + props.match.params.id)
      .then(async (response) => {
       const responseData = response.data._source;
        const { modificado_por, modifica_a } = responseData;

        const sortByFechaRelacionDesc = (arr) => arr?.sort((a, b) => new Date(b.fecha_relacion) - new Date(a.fecha_relacion));

        const sorted_modificado_por = sortByFechaRelacionDesc(modificado_por);
        const sorted_modifica_a = sortByFechaRelacionDesc(modifica_a);
        
        setData({...responseData, modificado_por: sorted_modificado_por, modifica_a: sorted_modifica_a });

        if (modificado_por && modificado_por.length > 0) {
          const responseTextoVigente = await axiosBaseURL.get(
            "/api/searchid/" + responseData.texto_vigente
          );
          setDataTextoVigente(responseTextoVigente.data._source);
        }
        const imageMap = {
          "Otra documentación": imgOtr,
          Disposición: imgDis,
          Dictamen: imgDic,
          ...(responseData.tipo_documento.includes("Resolución")
            ? { Resolución: imgRes }
            : {}),
        };
        const imageKey = Object.keys(imageMap).find((key) =>
          responseData.tipo_documento.includes(key)
        );
        const imageUrl = imageKey ? imageMap[imageKey] : imgNoD;
        setImageUrl(imageUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.match.params.id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year}`;
  };

  const renderDoc = () => (
    <>
      <Row className="detalleDescripcion">
        <p>{data && data.description}</p>
      </Row>
      {(data.modifica_a && data.modifica_a.length > 0) && renderModifica_a()}
      {(data.modificado_por && data.modificado_por.length > 0) && renderModificado_por()}
      <Row className="py-3">
        <Col xs={10} md={10} sm={10} lg={10} xl={10} xxl={10}>
          <h1>Texto Original:</h1>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2} xl={2} xxl={2}>
          <div className="paddingLeftButtons floatleft">
            <Buttons2
              name={data.name}
              doc_id={data.doc_id}
              base64data={data.pdf_base64}
            />
          </div>
        </Col>
      </Row>
      <Row className="pb-1 pt-4 text-center">
        <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} className="centerElems">
          <div className="modalStyle">
          <DetailPdfWrapper base64data={data && data.pdf_base64}></DetailPdfWrapper>
          </div>
        </Col>
      </Row>
    </>
  );

  const renderModificado_por = () => (
    <><Row className="py-5">
          <Col xs={10} md={10} sm={10} lg={10} xl={10} xxl={10}>
            <h1>Texto Vigente:</h1>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2} xl={2} xxl={2}>
            <div className="paddingLeftButtons floatleft">
              {dataTextoVigente && (
                <Buttons2
                  name={"Texto Vigente de " + dataTextoVigente.name}
                  doc_id={data.texto_vigente}
                  base64data={dataTextoVigente.pdf_base64} />
              )}
            </div>
          </Col>
        </Row><Row className="pb-1 pt-4 text-center">
            <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} className="centerElems">
              <div className="modalStyle">
                <DetailPdfWrapper base64data={dataTextoVigente && dataTextoVigente.pdf_base64}></DetailPdfWrapper>
              </div>
            </Col>
          </Row>
          <Row className="mb-3 py-5">
            <Col
              className="py-2 font-weight-bold"
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <h1>Modificado Por:</h1>
            </Col>

            <Row>
              <div className="py-2 pl-3 pr-5">
                {data.modificado_por.map((relacionado, index) => (
                  <li key={index} className="my-2 ">
                    <Link
                      to={"/searchDetail/" + relacionado.doc_id_relacionado}
                      className="text-modificatorias text-dark"
                    >
                      {relacionado.doc_name ? relacionado.doc_name : (relacionado.name_relacionado ? relacionado.name_relacionado : "Documento")}
                    </Link>
                  </li>
                ))}
              </div>
              <div className="py-2 ">
                {data.modificado_por.map((relacionado, index) => (
                  <p key={index} className=" my-2">
                    Fecha: {formatDate(relacionado.fecha_relacion)}
                  </p>
                ))}
              </div>
            </Row>
          </Row></>
  )

  const renderModifica_a = () => (
    <Row className="mb-3 py-5">
            <Col
              className="py-2 font-weight-bold"
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <h1>Modifica a:</h1>
            </Col>

            <Row>
              <div className="py-2 pl-3 pr-5">
                {data.modifica_a.map((relacionado, index) => (
                  <li key={index} className="my-2 ">
                    <Link
                      to={"/searchDetail/" + relacionado.doc_id_relacionado}
                      className="text-modificatorias text-dark"
                    >
                      {relacionado.doc_name ? relacionado.doc_name : (relacionado.name_relacionado ? relacionado.name_relacionado : "Documento")}
                    </Link>
                  </li>
                ))}
              </div>
              <div className="py-2 ">
                {data.modifica_a.map((relacionado, index) => (
                  <p key={index} className=" my-2">
                    Fecha: {formatDate(relacionado.fecha_relacion)}
                  </p>
                ))}
              </div>
            </Row>
          </Row>
  );

  return (
    <div className="w-100 ">
      <Row className="">
        <Col xs={11}/>
        <Back />
      </Row>
      <Row className="pt-4 pb-5">
        <Col xs={1} md={1} sm={1} lg={1} xl={1} xxl={1} />
        <Col xs={10} md={10} sm={10} lg={10} xl={10} xxl={10}>
          <Row className="alignCenter">
            <Col className="" xs={3} md={2} sm={2} lg={2} xl={1} xxl={1}>
              <img className="py-1" alt="" src={imageUrl}></img>
            </Col>
            <Col className="fitHeight padding0">
              <div className="pl-2 mt-2">
                <h1>{data && data.name}</h1>
              </div>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={10} md={10} sm={10} lg={10} xl={10} xxl={10}>
              <div className="floatleft padding7top">
                <Chip
                  className="border-radius5"
                  label={data && data.dependencia}
                />
              </div>
              <div className="padding10left padding7top floatleft">
                <Chip
                  className="border-radius5"
                  label={data && data.tipo_documento}
                />
              </div>
              <div className="padding10left padding7top floatleft">
                <Chip className="border-radius5" label={data && data.anio} />
              </div>
            </Col>
          </Row>
          {renderDoc()}
        </Col>
        <Col xs={1} md={1} sm={1} lg={1} xl={1} xxl={1} />
      </Row>
    </div>
  );
};

export default withRouter(DetalleMobile);

import { createBrowserHistory } from "history";
import React, { Component } from "react";
import { Router } from "react-router-dom";
import "../../Styles/styles.css";
import BuscadorWrapper from "../Buscador/BuscadorWrapper";
import Header from "../Header";
import AppBodyWrapper from "./AppBodyWrapper";

//import Buscador2 from './Buscador2';


const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href =
  "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

export const customHistory = createBrowserHistory();


export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: sessionStorage.getItem("isAuthenticated") === "true",
    }
  }
  
  componentWillMount() {
    document.body.classList.add("backgroundImg");

  }

  componentWillUnmount() {
    document.body.classList.remove("backgroundImg");
  }

  handleAdminAuthentication = () => {
    const username = prompt("Ingrese su usuario:");
    const password = prompt("Ingrese su contraseña:");

    if (username === "admin" && password === "Normativa2023!") {
      sessionStorage.setItem("isAuthenticated", "true");
      this.setState({ isAuthenticated: true });
      window.location.href = "/admin"; // Redirige a /admin si la autenticación es exitosa
    } else {
      alert("Credenciales inválidas. Inténtelo nuevamente.");
      window.location.href = "/";
    }
  };

  render() {
    return (
      <Router history={customHistory}>
        <Header />
        <div className="titleRow"></div>
        {!window.location.pathname.startsWith("/admin") && (
          <BuscadorWrapper/>
        )}
        <AppBodyWrapper isAuthenticated={this.state.isAuthenticated}
          handleAdminAuthentication={this.handleAdminAuthentication}/>
      </Router>
    );
  }
}

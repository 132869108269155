import React, { Component } from 'react'
import logo from '../Images/Buscador.png'
import img from '../Images/logo_cmcaba.png'
import '../Styles/styles.css'
// import logout from '../images/Logout.png';
import { Col } from 'react-bootstrap'

class Header extends Component {
  logOut () {

  }

  render () {
    return (
      <div className='headerRow'>
        <Col xs={1} sm={1} md={1} lg={1} />
        <Col xs={10} sm={5} md={5} lg={5}>
          <a href='/'>
            <img src={logo} alt='logo' className='headerLogo' />
          </a>
        </Col>
        <Col sm={5} md={5} lg={5} className='d-flex justify-content-end'>
          <img src={img} alt='logoCMCABA' className='headerImg' />
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} />
      </div>
    )
  }
}

export default Header

/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import '../../Styles/styles.css';
import ResultadoCompleto from '../Resultado/ResultadoCompleto';
import axiosBaseURL from '../httpCommon';

class ListaResultadosAvanzada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            page: 1,
            dateSelected: false,
            selectedYear: new Date(),
            rowsPerPage: 10,
            isFetching: true,
            filterOptions: [
                {
                  key: 'Año',
                  text: 'Año',
                  value: 'Año',
                },
                {
                    key: 'Nombre',
                    text: 'Nombre',
                    value: 'Nombre',
                  },
                {
                  key: 'Tipo de Documento',
                  text: 'Tipo de Documento',
                  value: 'Tipo de Documento',
                },
            ],
            orderOptions: [
                {
                  key: 'Ascendente',
                  text: 'Ascendente',
                  value: 'Ascendente',
                },
                {
                  key: 'Descendente',
                  text: 'Descendente',
                  value: 'Descendente',
                },
            ],
            paginationOptions: [
                {
                    key: '10',
                    text: '10',
                    value: 10,
                },
                {
                    key: '50',
                    text: '50',
                    value: 50,
                },
                {
                    key: '100',
                    text: '100',
                    value: 100,
                },
            ],
            contents:[],
            tipodocumentos: [],
            yearsFilter: [],
            tipodocumentosFilter: [],
            tipodocumentosFilterStrings: [],
            order:"Año",
            typeOrder:"Descendente",
            typeOfResultsText: "Mostrar resultados similares",
        };

        this.handleChangeTipoDoc = this.handleChangeTipoDoc.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.processTipoDocumentos = this.processTipoDocumentos.bind(this);
        this.processYears = this.processYears.bind(this);
        this.filtrarPorAnio = this.filtrarPorAnio.bind(this);
        this.filtrarPorTipoDoc = this.filtrarPorTipoDoc.bind(this);

    };

    componentWillMount(){

        axiosBaseURL.get('/api/gettipodocs')
        .then(response => {
            //console.log(response.data.hits.hits.map(h => h.fields.titulo[0]));
            let tipoDocsResult = response.data.hits.hits.map(h => h.fields.titulo[0])
            tipoDocsResult.push("No Determinado")
            this.setState({
                tipodocumentos: tipoDocsResult
            });
            console.log(tipoDocsResult)
        })

        this.getExactResults();
    }

    getExactResults = async () => {
        let advancedSearchUrl = this.buildAdvancedUrl();
        return axiosBaseURL.get(advancedSearchUrl)
        .then(response => {
            console.log(response.data.hits.hits);
            this.setState({
                data: response.data.hits.hits,
                isFetching: false
            });
            this.processFilters(response.data.hits.hits.map(h => h.fields));

            })
            .catch(error =>{
                console.log(error);
            });
    }

    resetPagination = () => {
        this.setState({
            page: 1,
        })
    }

    buildAdvancedUrl(){
        let url = '/api/advancedsearch/'
        const queryParams = [];

        if (this.props.input) {
        queryParams.push(`text=${encodeURIComponent(this.props.input)}`);
        }
        if (this.props.size) {
        queryParams.push(`size=${encodeURIComponent(this.props.size)}`);
        }
        if (this.props.from) {
        queryParams.push(`from=${encodeURIComponent(this.props.from)}`);
        }
        if (this.props.years) {
        queryParams.push(`years=${encodeURIComponent(this.props.years)}`);
        }
        if (this.props.dependencies) {
        queryParams.push(`dependencies=${encodeURIComponent(this.props.dependencies)}`);
        }
        if (this.props.number) {
        queryParams.push(`number=${encodeURIComponent(this.props.number)}`);
        }

        // If there are query parameters, join them with "&" and append to the URL
        if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
        }

        return url;
    }

    processFilters(data){
        this.processYears(data);
        this.processTipoDocumentos(data);
    }

    processTipoDocumentos(data){
        let tipoDocArrCount = new Array(this.state.tipodocumentos.length).fill(0);

        for(let i=0; i<data.length; i++){
            for(let j=0; j<this.state.tipodocumentos.length; j++){
                if(data[i].tipo_documento[0].toUpperCase() === this.state.tipodocumentos[j].toUpperCase()){
                    tipoDocArrCount[j]++;
                    break;
                }
            } 
        }

        let auxArr = [];

        for(let k=0; k<this.state.tipodocumentos.length; k++){
            if(tipoDocArrCount[k] > 0){
                auxArr.push (this.state.tipodocumentos[k] + ' (' + tipoDocArrCount[k] + ')');
            }
        }

        this.setState({tipodocumentosFilter: auxArr});
    }
     
    processYears(data){
        let auxArr = [];
        for(let i=0; i<data.length; i++){
            if(!auxArr.includes(data[i].anio[0])){
                auxArr.push(data[i].anio[0]);
            }
        }
        auxArr.sort()
        let yearObj=[{key: "Todos", text:"Todos", value: "Todos"}];
        for(let j=0; j<auxArr.length; j++){
            yearObj.push({
                key:auxArr[j], text:auxArr[j], value: auxArr[j]
            })
        } 
        this.setState({yearsFilter: yearObj})
    }

    filtrarPorAnio(obj){
        if(this.state.dateSelected && this.state.selectedYear !== "Todos"){
            if (obj.fields.anio[0] === this.state.selectedYear){
                return true;
            } else {
                return false;
            }
        }else{
            return true;
        }
        
    }
    
    filtrarPorTipoDoc(obj){
        if(this.state.tipodocumentosFilterStrings.length === 0) return true;

        for(let i=0; i<this.state.tipodocumentosFilterStrings.length; i++){
            if(obj.fields.tipo_documento[0] === this.state.tipodocumentosFilterStrings[i]){
                return true;
            }
        } 
        return false;
    }

    handleChangeTipoDoc = (e) => {
        this.resetPagination();
       let values = e.target.name.split('(');
       let name = values[0].substring(0, values[0].length-1);
       
       let newArray = [...this.state.tipodocumentosFilterStrings]

       if(e.target.checked == true){
            newArray.push(name);
       }else{
            const index = newArray.indexOf(name);
            if(index > -1){
                newArray.splice(index, 1);
            }
       }

       this.setState({
            tipodocumentosFilterStrings : newArray
       })

    };

    handleChangeRowsPerPage = (event, data) => {
        console.log(data.value)
        this.setState({
            rowsPerPage: data.value,
            page: 1
        })
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })
    };

    handleDateChange = (e, data) => {
        this.resetPagination();
        this.setState({
            selectedYear: data.value,
            dateSelected: true
        })
    }

    handleTypeOrderChange = (event, data) => {
        this.setState({
            typeOrder: data.value
        })
    }

    handleOrderChange = (event, data) => {
        this.setState({
            order: data.value
        })
    }

    render(){
       
        return (
            <Container fluid className="listaResultados" >
                 {
                    this.state.data.length === 0 && this.state.isFetching ? 
                    <div className="loadingWrapper ">
                        <div className="loadingElem">
                            <CircularProgress size="50px"/> 
                        </div> 
                    </div>
                    :
                    <div>
                <Row className="padding50top paddingLeftButtons">
                    <Col xs={2} md={2} sm={2} lg={2} xl={2} xxl={2}>
                        <Row>
                            <Card className="cardFilter"  style={{ border: "none", boxShadow: "none" }}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Tipo de Documento
                                    </Typography>
                                    <hr></hr>
                                    <FormGroup>
                                        {this.state.tipodocumentosFilter.map((type) => (
                                                <FormControlLabel className="lineHeight1 padding5bottom"
                                                    control={<Checkbox onChange={this.handleChangeTipoDoc} name={`${type}`} />}
                                                    label={`${type}`}
                                                    key={`default-${type}`}
                                                />
                                        ))}
                                        
                                        
                                    </FormGroup>
                                </CardContent>
                            </Card>
                        </Row>

                        <Row className="padding40top">
                            <Card className="cardFilter" style={{ border: "none", boxShadow: "none"  }}>
                                <CardContent className="cardContentAñoFilter">
                                    <Typography variant="h5" component="h2">
                                    Año
                                    </Typography>
                                    <hr></hr>
                                    
                                </CardContent>
                            </Card>
                            <Dropdown
                                className = "yearDropdownFilter"
                                placeholder='Seleccionar Año'
                                defaultValue = "Todos"
                                options={this.state.yearsFilter}
                                onChange={this.handleDateChange}
                            />
                        </Row>
                        <Row className="padding50top"></Row>
                    </Col>
                    <Col xs={10} md={10} sm={10} lg={10} xl={10} xxl={10}>
                        <Row className='padding15top'>
                            <Col xs={7} md={7} sm={7} lg={7} xl={7} xxl={7}>
                                <span className="padding10right padding30left" >Se encontraron <Chip  label={this.state.data.length}/> resultados ordenados por      </span>                           
                                <Menu compact className='m-2' >
                                    <Menu.Menu>
                                        <Menu.Item className="">
                                            <Dropdown
                                                placeholder='Ordenar por'
                                                defaultValue= "Año"
                                                options={this.state.filterOptions}
                                                onChange={this.handleOrderChange}
                                            />
                                        </Menu.Item>
                                        <Menu.Item className="">
                                            <Dropdown
                                                placeholder='Tipo de orden'
                                                defaultValue= "Descendente"
                                                options={this.state.orderOptions}
                                                onChange={this.handleTypeOrderChange}
                                            />
                                        </Menu.Item>
                                    </Menu.Menu>
                                </Menu>
                            </Col>
                            <Col xs={1} md={1} sm={1} lg={1} xl={1} xxl={1}/>
                            <Col xs={4} md={4} sm={4} lg={4} xl={4} xxl={4}>
                                <span className="padding10right" >Mostrar</span>                           
                                <Menu compact >
                                    <Menu.Menu>
                                        <Menu.Item className="">
                                            <Dropdown
                                                placeholder='Cantidad'
                                                value = {this.state.rowsPerPage}
                                                onChange = {this.handleChangeRowsPerPage}
                                                options={this.state.paginationOptions}
                                            />
                                        </Menu.Item> 
                                    </Menu.Menu>       
                                </Menu>
                                <span className="padding10left" >resultados por pagina</span> 
                            </Col>
                        </Row>
                        <Row>
                            {
                            this.state.data
                            .filter(this.filtrarPorAnio)
                            .filter(this.filtrarPorTipoDoc)
                            .sort(
                                (a,b) => {
                                      switch(this.state.order) {

                                        case "Año":
                                            
                                          if(this.state.typeOrder === 'Ascendente'){
                                            return a.fields.anio[0] > b.fields.anio[0] ? 1 : -1;
                                          }else if(this.state.typeOrder === 'Descendente'){
                                            return a.fields.anio[0] > b.fields.anio[0] ? -1 : 1;
                                          }
                                          break;  

                                        case "Nombre":
                                          if(this.state.typeOrder === 'Descendente'){
                                            return a.fields.name[0] > b.fields.name[0] ? 1 : -1;
                                          }else if(this.state.typeOrder === 'Ascendente'){
                                            return a.fields.name[0] > b.fields.name[0] ? -1 : 1;
                                          }
                                          break; 
                                        
                                        case "Tipo de Documento":
                                          if(this.state.typeOrder === 'Descendente'){
                                            return a.fields.tipo_documento[0] > b.fields.tipo_documento[0] ? 1 : -1;
                                          }else if(this.state.typeOrder === 'Ascendente'){
                                            return a.fields.tipo_documento[0] > b.fields.tipo_documento[0] ? -1 : 1;
                                          } 
                                        }
                                    })              
                            .slice((this.state.page - 1) * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage)                      
                            .map(item => {
                                return (
                                <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12}>
                                    <ResultadoCompleto selectResult={this.props.selectResult} onDetailSelect={this.props.onDetailSelect} key={item.fields.doc_id} title={item.fields.name} name={item.fields.name} dependencia={item.fields.dependencia} año={item.fields.anio} doc_id={item.fields.doc_id} base64data={item.fields.data} tipo={item.fields.tipo_documento} value={this.props.input} description={item.fields.description} highlight={item.highlight?item.highlight["attachment.content"][0]: undefined}/>
                                </Col>  
                                );
                            })}                                                 
                        </Row>
                    </Col>
                </Row>
                <Row className="padding30top padding15bottom" style={{ justifyContent: 'center' }}>
                <Pagination 
                    count={Math.ceil(
                        this.state.data.filter(this.filtrarPorAnio)
                        .filter(this.filtrarPorTipoDoc).length/this.state.rowsPerPage)}
                    page={this.state.page}
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    showFirstButton
                    showLastButton
                />                      
            </Row>
            </div>
                }
            </Container>
        )

    }}

export default withRouter(ListaResultadosAvanzada)
// xs={} md={} sm={} lg={} xl={} xxl={}

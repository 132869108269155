import React from "react";
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Menu, Search } from 'semantic-ui-react';

const BuscadorMobile = () => {

 const initialState = {
    loading: false,
    results: [],
    value: '',
    open:false,
    error: '',
    baseUrl: window.location.href,
    printed:false,
    detailed:false,
  }

  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  const { loading, value} = state;

  let history = useHistory();
  const timeoutRef = React.useRef();

  function exampleReducer(state, action) {
    switch (action.type) {
      case 'CLEAN_QUERY':
        return initialState
      case 'ENTER_SEARCH':
          return {...state, loading: true, printed: false, open: false, detailed:false}
      case 'ENTER_FINISH_SEARCH':
          return {...state,loading: false, printed: true, open: false}
      case 'CHANGE_DETAIL_DATA':
          return {...state, doc_id: action.idVal, name: action.nameVal, data: action.dataVal, tipo: action.tipoVal, dependencia: action.dependenciaVal, año: action.añoVal, description: action.descriptionVal}
      case 'RESET_PRINTED':
        return{...state, printed: false}

      default:
        throw new Error()
    }
  }


  const enterSearch = React.useCallback((e) => {
        clearTimeout(timeoutRef.current);
        if(e.target.value !== ''){
          timeoutRef.current = setTimeout(() => {
            dispatch({ type: 'ENTER_SEARCH'});
            
          }, 500);

          timeoutRef.current = setTimeout(() => {
            let url = "/searchAll/" + encodeURIComponent(e.target.value) + '/100' + '/0';
            history.push(url);
            dispatch({ type: 'ENTER_FINISH_SEARCH'});
          }, 700);
        }        
  })

  return (
    <Row className="nomargin">
      <div className="padding-5 fitWidth">
        <Menu>
          <Menu.Item className="searcherItem">
            <Search
            loading={loading}
            open={false}
            onSearchChange={enterSearch}
            minCharacters={3}
            className="width100"                                
            placeholder="Ingrese el texto a buscar en los documentos"
            />
          </Menu.Item>
        </Menu>
      </div>
     </Row>
  );
};


export default BuscadorMobile;
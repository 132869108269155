import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import React, { Component } from 'react';
import axiosBaseURL from './httpCommon';
import ShareButton from './ShareButton';
export default class Buttons2 extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            baseUrl:  window.location.href,
        };
        
       // this.searchForDownload= this.searchForDownload.bind(this);
    }
       
    render(){
        let b64 = 'data:application/pdf;base64,';
        //console.log(b64)
        let name = this.props.name + '.pdf'
        let printFunc = ()=>{

            axiosBaseURL.get('/api/searchid/'+this.props.doc_id)
                .then(response => {
                    //console.log(response.data._source.data);
                    b64 = 'data:application/pdf;base64,'+response.data._source.pdf_base64;
                    let pdfWindow = window.open("")
                    pdfWindow.document.write("<iframe width='100%' height='100%' src='"+encodeURI(b64) + "'></iframe>")
                    //this.setState({base64data: response.data._source.data});
                }).catch(error =>{
                    console.log(error);
                });           
        }

        
        let downloadFunc = () => {

            axiosBaseURL.get('/api/searchid/'+this.props.doc_id)
            .then(response => {
                //console.log(response.data._source.pdf_base64);

                const linkSource = 'data:application/pdf;base64,'+response.data._source.pdf_base64;
                const downloadLink = document.createElement("a");
                const fileName = this.props.name;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                //this.setState({base64data: response.data._source.data});
            }).catch(error =>{
                console.log(error);
            });  
            
        }
        
        return(
            <div>

                    <IconButton aria-label="download" size="small" onClick={downloadFunc}>
                        <GetAppIcon fontSize="small" />
                    </IconButton>
                <IconButton aria-label="print" size="small" onClick={printFunc}>
                    <PrintIcon fontSize="small"/>
                </IconButton>
                <ShareButton doc_id={this.props.doc_id}/>
            </div>
            );
    }
    
}

import Chip from '@material-ui/core/Chip';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import imgDic from '../../Images/TypesOfDocs/dictamenes.png';
import imgDis from '../../Images/TypesOfDocs/disposiciones.png';
import imgNoD from '../../Images/TypesOfDocs/no determinado.png';
import imgOtr from '../../Images/TypesOfDocs/otros.png';
import imgRes from '../../Images/TypesOfDocs/resoluciones.png';
import '../../Styles/styles.css';
import Buttons from '../Buttons';
import ClickAndPreview from '../ClickAndPreview';


export default class Resultado extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: imgDis,
            redirect:false,
        };
    }
        
    componentDidMount(){
        const imageMap = {
            "Otra documentación": imgOtr,
            "Disposición": imgDis,
            "Dictamen": imgDic,
            // Map any tipo that starts with "Resolucion" to imgRes
            ...this.props.fields["tipo_documento.keyword"].reduce((acc, t) => t.startsWith("Resolución") ? {...acc, [t]: imgRes} : acc, {})
          }
          
        const imageUrl = imageMap[this.props.fields["tipo_documento.keyword"][0]] || imgNoD;
        this.setState({ imageUrl });

        if(this.props.fields.description[0] === ""){
            if(this.props.highlight["attachment.content"][0] === ""){
                this.setState({
                    description: ["Sin descripcion"]
                })  
            }else{
                this.setState({
                    description: this.props.highlight["attachment.content"]
                }) 
                //console.log(this.props.highlight["attachment.content"])            
            }

        }else{
            this.setState({
                description: this.props.fields.description
            })
        }

    

        
    }
    
    getHighlightedText = (text, highlight) => {
        // Escape highlight for safe use in regular expression
        const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Split text using escaped highlight as a regular expression
        const parts = text.split(new RegExp(`(${escapedHighlight.replace(/\s+/g, "\\s+")})`, 'gi'));

        return (
        <span>
            {parts.map((part, i) => (
            <span
                key={i}
                style={{
                background:
                    part.replace(/\s+/g, " ").toLowerCase() === highlight.toLowerCase()
                    ? '#e3e06f'
                    : 'transparent'
                }}
            >
                {part}
            </span>
            ))}
        </span>
        );
    }



    handleDetail = (e) => {
        this.setState({redirect: true});
    }

    render() {
        if (this.state.redirect) {            
            let url = "/searchDetail/" + this.props.fields.doc_id;
            this.setState({redirect: false})
            return <Redirect push to={url} />;
        }
        return (
            <div className="d-flex align-items-center fitHeight " style={{cursor:"pointer"}}>
                <div className="ContainerCustom"  onClick={this.handleDetail}>
                    
                        <Row className="margin0 fitWidth fitHeight">
                          
                          <Col  xs={3} md={3} sm={3} lg={2} xl={1} xxl={1}>
                            <img className="resultIMG" src={this.state.imageUrl}></img>
                          </Col>
                          
                          <Col xs={6} md={7} sm={8} lg={9} xl={11} xxl={11} className=" padding0left fitHeight">
                           
                            <Row className="margin0 fitWidth" >
                                <Col xs={12} md={12} sm={12} lg={12} xl={12} xxl={12} className="fitHeight padding0">
                                    <div className="resultText title">{this.getHighlightedText((this.props.fields.name+ '').substring(0,400), this.props.value+ '')}</div>
                                </Col>
                            </Row>
                            
                            <Row className="descriptionText">
                             { this.getHighlightedText((this.state.description+ '').substring(0,150) + '...', this.props.value+ '')}
                            </Row>
                            
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={8}> 
                                    <div className="">
                                        <div className="floatleft padding7top">
                                            <Chip className="border-radius5" label={this.props.fields.dependencia}/>
                                        </div>
                                        <div className="padding10left padding7top floatleft">
                                            <Chip className="border-radius5" label={this.props.fields["tipo_documento.keyword"][0]}/>
                                        </div>
                                        <div className="padding10left padding7top floatleft">
                                            <Chip className="border-radius5" label={this.props.fields.anio}/>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <div className="">
                                        <div className="paddingLeftButtons2 floatleft">
                                            <Buttons name={this.props.fields.name} doc_id={this.props.fields.doc_id}/>
                                        </div>
                                        <div className="clickAndPreviewWidth padding7top floatleft" >
                                            <ClickAndPreview doc_id={this.props.fields.doc_id}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                          </Col>
                        </Row>
        
                </div>
            </div>
        );
     
    }
  
  
}
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import '../Styles/styles.css';
import axiosBaseURL from './httpCommon';
import Pdf from './Pdf';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 45;
  const left = 45;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: '97.5vh',
    width: '68vh',

  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    'border-radius':'5px',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(0, 0, 0),
    display:'inline-flexe-flex',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [b64, setFile] = React.useState("");

  const handleOpen = (e) => {

    axiosBaseURL.get('/api/searchid/'+props.doc_id)
        .then(response => {
          //console.log(response.data._source.data);
          setFile(response.data._source.pdf_base64);
          setOpen(true);
        }).catch(error =>{
          console.log(error);
        });    
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <Pdf base64data={b64}/>
    </div>
  );

  return (
    <div className="prevButtonDiv">
      <Button className="prevButton" color='grey' onClick={(e)=>{ e.stopPropagation(); handleOpen(e)}}>
        <Icon name='search'/><p className="prevButtonText prevButton">Previsualizar</p>
      </Button>
      <Modal
        open={open}
        onClick={(e)=>{ e.stopPropagation(); }}
        onClose={(e)=>{e.stopPropagation(); handleClose(e)}}
      >
        {body}
      </Modal>
    </div>
  );
}

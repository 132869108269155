import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { addLocale, locale } from 'primereact/api';
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import ClickAndPreview2 from "../ClickAndPreview2";
import axiosBaseURL from "../httpCommon";
import Buscador from "./Buscador";


locale('es');
addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  today: 'Hoy',
  clear: 'Limpiar',
  dateFormat: 'dd/mm/yy'
  //...
});


const Relacion = () => {
  const [formValues, setFormValues] = useState({
    modificatorias: [
      {
        docmodificatoriaid: null,
        docmodificatorianame: null,
        tiporelacion: [
          {
              "relacion": "Actualiza A",
              "inversa": "Es actualizada por"
          }
        ],
        fecha: "",
      },
    ],
    pdftextovigente: null,
    docoriginal: null,
  });
  const [tipoRelaciones, setTipoRelaciones] = useState([
    { relacion: "Actualiza A", inversa: "Es actualizada por" },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [flagB64, setFlagB64] = useState(false);
  const [b64, setB64] = useState(false);
  const toast = useRef(null);

  /*const loadRelaciones = async () => {
    try {
      const response = await axiosBaseURL.get("/api/gettiporelaciones");
      const tipoRelacionesResult = response.data.hits.hits.map((obj) => ({
        relacion: obj.fields.nombre[0],
        inversa: obj.fields.inversa[0],
      }));
      setTipoRelaciones(tipoRelacionesResult);
    } catch (error) {
      console.log(error);
    }
  };*/

  const addmodificatorias = (defaultValue) => {
    const updatedmodificatorias = [
      ...formValues.modificatorias,
      { tiporelacion: [
        {
            "relacion": "Actualiza A",
            "inversa": "Es actualizada por"
        }
      ], fecha: "" },
    ];
    setFormValues({ ...formValues, modificatorias: updatedmodificatorias });
  };

  const removemodificatorias = (index) => {
    const updatedmodificatorias = [...formValues.modificatorias];
    updatedmodificatorias.splice(index, 1);
    setFormValues({ ...formValues, modificatorias: updatedmodificatorias });
  };

  const handleInputChange = (index, key, value) => {
    const updatedmodificatorias = [...formValues.modificatorias];
    updatedmodificatorias[index][key] = value;
    setFormValues({ ...formValues, modificatorias: updatedmodificatorias });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target.result.substring(
        e.target.result.indexOf(",") + 1
      );
      setFormValues({ ...formValues, pdftextovigente: fileData });
      setFlagB64(true);
      setB64(fileData);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axiosBaseURL
      .post(`/api/addrelaciones`, formValues)
      .then((response) => {
        setOpenModal(true);
      })
      .catch((error) => {
        const errorMessage = error.response.data.error;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 3000,
        });
      });
  };

  const handleRelacionData = (data) => {
    setFormValues({ ...formValues, docoriginal: data[0] });
  };

  const handleCloseModal = () => {
    setOpenModal(false); 
    window.location.href = "/admin";
  };

  const handleRelacionDataMod = (data, index) => {
    handleInputChange(index, "docmodificatoriaid", data[0]._id);
    handleInputChange(index, "docmodificatorianame", data[0].fields.name[0]);
  };

  return (
    <div className="ml-5">
      <h1>Nuevo Análisis Normativo</h1>
      <Toast ref={toast} position="top-center" />
      {/* DOCUMENTO ORIGINAL */}
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modalConfirmRelacion">
          <p className="d-flex justify-content-center">
            La relación se guardó correctamente
          </p>
          <button
            className="ui button saveButton d-flex justify-content-center"
            style={{ margin: "auto", marginBottom: "20px" }}
            // onClick={() => setOpenModal(false)}
            onClick={handleCloseModal}
          >
            Aceptar
          </button>
        </div>
      </Modal>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          as={Row}
          className="d-flex align-items-center"
          controlId="formHorizontalEmail"
        >
          <Form.Label column sm={12}>
            <p className="font-weight-bold">Documento Original</p>
          </Form.Label>
          <Col sm={12} className="ml-3">
            <Buscador
              key={window.location.pathname}
              onData={handleRelacionData}
            />
          </Col>
        </Form.Group>

        {/* MODIFICATORIAS */}

        {formValues.modificatorias.map((element, index) => (
          <Form.Group
            as={Row}
            className="d-flex align-items-center"
            controlId="formHorizontalPassword"
            key={index}
          >
            <Form.Label column sm={12}>
              <p className="font-weight-bold">Modificatoria</p>
            </Form.Label>

            <Col sm={12} className="ml-3">
              <Buscador
                key={index}
                onDataMod={(data) => handleRelacionDataMod(data, index)}
              />
            </Col>
            <div className="d-flex justify-content-start align-items-center">
              <Col className="pr-0">
                <MultiSelect
                  disabled
                  value={formValues.modificatorias[index].tiporelacion}
                  onChange={(e) =>
                    handleInputChange(index, "tiporelacion", e.value)
                  }
                  options={tipoRelaciones}
                  optionLabel="relacion"
                  filter
                  placeholder="Actualiza A"
                  maxSelectedLabels={3}
                  selectionLimit={1}
                  style={{ width: "175px" }}
                  className="md:w-20rem"
                />
              </Col>

              <Col className="">
                <div>
                  <Calendar
                    placeholder="Seleccione la fecha"
                    value={formValues.modificatorias[index].fecha}
                    onChange={(e) =>
                      handleInputChange(index, "fecha", e.target.value)
                    }
                  />
                </div>
              </Col>
            </div>

            <Col sm={2}>
              {index ? (
                <IconButton
                  aria-label="print"
                  size="small"
                  onClick={() => removemodificatorias(index)}
                >
                  <RemoveCircleOutlineIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="print"
                  size="small"
                  onClick={() => addmodificatorias()}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Col>
          </Form.Group>
        ))}

        {/*END  MODIFICATORIAS */}

        {/* TEXTO VIGENTE */}

        <Form.Group
          as={Row}
          className="d-flex align-items-center mt-5 pb-3"
          controlId="formTextoVigente"
        >
          <Form.Label column sm={12}>
            <p className="font-weight-bold">Texto Vigente</p>
          </Form.Label>
          <Col sm={2}>
            <input
              type="file"
              accept=".pdf"
              className="pt-3 inputTextoVigente"
              onChange={handleFileChange}
            />
          </Col>
          <Col sm={3}>
            {flagB64 && (
              <div className="containerPdfTextoVigente">
                <div className="modalPreviewPDFRelacion">
                  <ClickAndPreview2 base64={b64} />
                </div>
              </div>
            )}
          </Col>
        </Form.Group>

        {/* BOTONES */}

        <Form.Group as={Row} className="my-5" controlId="formFile">
          <Col sm={{ span: 10, offset: 0 }}>
            <button
              className="ui button mr-3 saveButton"
              type="submit"
              disabled={
                !(
                  formValues.modificatorias[0].docmodificatoriaid !== null &&
                  formValues.docoriginal !== null
                )
              }
            >
              Guardar
            </button>
            <button className="ui button" type="button" onClick={() => (window.location.href = "/admin")}>
              Cancelar
            </button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Relacion;

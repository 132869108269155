import { FilterMatchMode } from 'primereact/api';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import dateService from "../../services/dates";
import documentService from "../../services/documents";
import AddModificatoria from "./AddModificatoria";
import EditModificatoria from "./EditModificatoria";

import "../../Styles/styles.css";
import "./admin.css";

import Pdf from "../Pdf";

import axiosBaseURL from "../httpCommon";

import "primeicons/primeicons.css";

export default function ListadoAnalisisNormativos() {
  const [pdf, setPdf] = useState();
  const [nameDocumento, setNameDocumento] = useState("");
  const [docOriginal, setDocOriginal] = useState("");
  const [docOriginalSelected, setDocOriginalSelected] = useState("");
  const [modificatoria, setModificatoria] = useState("");
  const [analisisNormativos, setAnalisisNormativos] = useState([]);
  const [pdfDialog, setPdfDialog] = useState(false);
  const [addModificatoriaDialog, setAddModificatoriaDialog] = useState(false);
  const [editModificatoriaDialog, setEditModificatoriaDialog] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [deleteModificatoriaDialog, setDeleteModificatoriaDialog] =
    useState(false);
  const [deleteDocOriginalDialog, setDeleteDocOriginalDialog] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    loadAnalisisNormativos();
  }, []);

  const loadAnalisisNormativos = async () => {
    try {
      documentService.getAnalisisNormativos().then((documents) => {
        setAnalisisNormativos(documents);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: 'docoriginal.fecha_creacion', order: -1 },
    { field: 'docoriginal.name', order: 1 },
  ]);


  const hidePdfDialog = () => {
    setPdfDialog(false);
    setPdf("");
  };

  const hideAddModificatoriaDialog = () => {
    setNameDocumento("");
    setAddModificatoriaDialog(false);
  };

  const hideEditModificatoriaDialog = () => {
    setModificatoria("");
    setDocOriginal("");
    setNameDocumento("");
    setEditModificatoriaDialog(false);
  };

  const hideAddModificatoriaDialogAndSuccess = () => {
    setAddModificatoriaDialog(false);
    window.location.reload();
  };

  const hideEditModificatoriaDialogAndSuccess = () => {
    setEditModificatoriaDialog(false);
    toast.current.show({
      severity: "success",
      summary: "Éxito",
      detail: "Modificatoria editada",
      life: 4000,
    });
    loadAnalisisNormativos();
  };

  const showPdf = (data) => {
    documentService.getPdfDocumentById(data.textovigente).then((base64) => {
      setPdf(base64);
    });
    setPdfDialog(true);
  };

  const showPdfDocOriginal = (data) => {
    documentService.getPdfDocumentById(data.docoriginal.id).then((base64) => {
      setPdf(base64);
    });
    setPdfDialog(true);
  };

  const showPdfModificatoria = (data) => {
    documentService.getPdfDocumentById(data.id).then((base64) => {
      setPdf(base64);
    });
    setPdfDialog(true);
  };

  const deleteModificatoria = () => {
    documentService
      .removeModificatoria(modificatoria.docoriginal.id, modificatoria.id)
      .then((response) => {
        console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Modificatoria eliminada",
          life: 3000,
        });
        loadAnalisisNormativos();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const deleteAllDocOriginal = (data) => {
    axiosBaseURL
      .post("/api/removealldocoriginal", {
        docid: docOriginalSelected.docoriginal.id,
      })
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Análisis Normativo eliminado",
          life: 3000,
        });
        loadAnalisisNormativos();
      })
      .catch((error) => {
        alert(error);
      });
  };

  // MOSTRAR EL DIALOG DE CONFIRMACION

  const showModificatoriaDialog = (data) => {
    setNameDocumento(data.modificatoria);
    setModificatoria(data);
    setDeleteModificatoriaDialog(true);
  };

  const showDocOriginalDialog = (data) => {
    setDocOriginalSelected(data);
    setNameDocumento(data.docoriginal.name);
    setDeleteDocOriginalDialog(true);
  };

  // CERRAR EL DIALOG

  const hideDeleteRowDialog = () => {
    setModificatoria("");
    setDocOriginalSelected("");
    setNameDocumento("");
    setDeleteModificatoriaDialog(false);
    setDeleteDocOriginalDialog(false);
  };

  // ONCLICK DE CONFIRMACION DEL BOTON BORRAR MODIFICATORIA
  const onClickDeleteModificatoria = () => {
    deleteModificatoria();
    setNameDocumento("");
    setDeleteModificatoriaDialog(false);
  };

  // ONCLICK DE CONFIRMACION DEL BOTON BORRAR DOC ORIGINAL
  const onClickDeleteDocOriginal = () => {
    deleteAllDocOriginal();
    setNameDocumento("");
    setDeleteDocOriginalDialog(false);
  };

  const pdfBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-file-pdf"
          severity="danger"
          text
          size="large"
          onClick={() => showPdf(rowData)}
        />
      </React.Fragment>
    );
  };

  const docOriginalBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <p
          className="text-docs text-dark"
          onClick={() => showPdfDocOriginal(rowData)}
        >
          {rowData.docoriginal.name}
        </p>
      </React.Fragment>
    );
  };

  const modificatoriBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <p
          className="text-docs text-dark"
          onClick={() => showPdfModificatoria(rowData)}
        >
          {rowData.modificatoria}
        </p>
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return dateService.formatDate(rowData.fechamodificatoria);
  };

  const createDateBodyTemplate = (rowData) => {
    return dateService.formatDate(rowData.docoriginal.fecha_creacion);
  };

  const editAndDeleteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          text
          className="mr-2"
          onClick={() => showEditModificatoria(rowData)}
        />
        <Button
          icon="pi pi-trash"
          text
          severity="danger"
          onClick={() => showModificatoriaDialog(rowData)}
        />
      </React.Fragment>
    );
  };

  // MOSTRAR EDICION DE MODIFICATORIAS

  const showEditModificatoria = (data) => {
    documentService.getDocumentById(data.docoriginal.id).then((document) => {
      setDocOriginal(document);
    });
    setModificatoria(data);
    setEditModificatoriaDialog(true);
  };

  const deleteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/* <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} /> */}
        <Button
          icon="pi pi-ban"
          text
          severity="danger"
          onClick={() => showDocOriginalDialog(rowData)}
        />
      </React.Fragment>
    );
  };

  const deleteModificatoriaDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteRowDialog}
      />
      <Button
        label="Si"
        icon="pi pi-trash"
        severity="danger"
        onClick={onClickDeleteModificatoria}
      />
    </React.Fragment>
  );

  const deleteDocOriginalDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteRowDialog}
      />
      <Button
        label="Si"
        icon="pi pi-trash"
        severity="danger"
        onClick={onClickDeleteDocOriginal}
      />
    </React.Fragment>
  );

  const addBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-plus-circle"
          text
          className="mr-2"
          severity="success"
          onClick={() => showAddModificatoria(rowData)}
        />
      </React.Fragment>
    );
  };

  const showAddModificatoria = (data) => {
    documentService.getDocumentById(data.docoriginal.id).then((document) => {
      setDocOriginal(document);
    });
    setAddModificatoriaDialog(true);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  return (
    <div className="card mb-5 mx-5">
      <Row>
        <Col xs={10}></Col>
        <Col xs={2}>
          <div className="flex">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar palabra clave" />
            </span>
          </div>
        </Col>
      </Row>

      <Toast ref={toast} position="bottom-right" />

      <DataTable
        tableStyle={{ width: '97.5rem' }}
        value={analisisNormativos}
        showGridlines
        rowGroupMode="rowspan"
        filters={filters}
        groupRowsBy={[
          "docoriginal.fecha_creacion",
          "docoriginal.name",
          "textovigente",
          "docoriginal.id",
        ]}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
        paginator
        rows={50}
        rowsPerPageOptions={[50, 75, 100]}
      >
        <Column
          field="docoriginal.fecha_creacion"
          header="Fecha Creacion"
          sortable
          body={createDateBodyTemplate}
          style={{ width: '10%' }}
        ></Column>

        <Column
          field="docoriginal.name"
          header="Documento Original"
          sortable
          body={docOriginalBodyTemplate}
          style={{ width: '25%' }}
        ></Column>
        <Column
          field="modificatoria"
          header="Documentación Modificatoria"
          sortable
          body={modificatoriBodyTemplate}
          style={{ width: '25%' }}
        ></Column>
        <Column
          field="fechamodificatoria"
          header="Fecha Modificatoria"
          sortable
          body={dateBodyTemplate}
          style={{ width: '10%' }}
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          field="textovigente"
          header="Texto Vigente"
          body={pdfBodyTemplate}
          style={{ width: '5%' }}
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          field="actions"
          header="Modificatorias"
          body={editAndDeleteBodyTemplate}
          style={{ width: '15%' }}
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          field="docoriginal.id"
          header="Agregar"
          body={addBodyTemplate}
          style={{ width: '5%' }}
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          field="docoriginal.id"
          header="Eliminar"
          body={deleteBodyTemplate}
          style={{ width: '5%' }}
        ></Column>
      </DataTable>

      <Dialog
        visible={pdfDialog}
        header="PDF"
        modal
        className="p-fluid"
        onHide={hidePdfDialog}
      >
        <Pdf base64data={pdf} />
      </Dialog>

      {/* DIALOG/MODAL PARA AGREGAR MODIFICATORIA */}

      <Dialog
        visible={addModificatoriaDialog}
        style={{ width: "75rem", marginTop: "100px" }}
        header={`Agregar nueva modificatoria a:   `}
        headerStyle={{ paddingLeft: "65px", paddingTop: "40px" }}
        modal
        className="p-fluid"
        onHide={hideAddModificatoriaDialog}
      >
        <div className="d-flex align-items-center pb-4">
          {docOriginal ? (
            <>
              <p className="font-italic pl-5 mb-0 font-weight-bold ">
                {docOriginal.name}
              </p>
              <div className=" ml-4">
                <Tag className="bg-secondary mr-2">
                  {docOriginal.tipo_documento}
                </Tag>
                <Tag className="bg-secondary mr-2">{docOriginal.anio}</Tag>
                <Tag className="bg-secondary mr-2">
                  {docOriginal.numero}
                </Tag>{" "}
              </div>
            </>
          ) : (
            <p className="ml-5">Cargando documento...</p>
          )}
        </div>
        <AddModificatoria
          data={docOriginal}
          handleCloseDialogEdit={hideAddModificatoriaDialogAndSuccess}
        ></AddModificatoria>
      </Dialog>

      {/* DIALOG/MODAL PARA EDITAR MODIFICATORIA */}

      <Dialog
        visible={editModificatoriaDialog}
        style={{ width: "75rem", marginTop: "100px" }}
        // breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={`Editar modificatoria a:   `}
        headerStyle={{ paddingLeft: "65px", paddingTop: "40px" }}
        modal
        className="p-fluid"
        onHide={hideEditModificatoriaDialog}
      >
        <div className="d-flex align-items-center pb-4">
          {docOriginal ? (
            <>
              <p className="font-italic pl-5 mb-0 font-weight-bold ">
                {docOriginal.name}
              </p>
              <div className=" ml-4">
                <Tag className="bg-secondary mr-2">
                  {docOriginal.tipo_documento}
                </Tag>
                <Tag className="bg-secondary mr-2">{docOriginal.anio}</Tag>
                <Tag className="bg-secondary mr-2">
                  {docOriginal.numero}
                </Tag>{" "}
              </div>
            </>
          ) : (
            <p className="ml-5">Cargando documento...</p>
          )}
        </div>
        {docOriginal && (
          <EditModificatoria
            data={docOriginal}
            modificatoria={modificatoria}
            handleCloseDialogEdit={hideEditModificatoriaDialogAndSuccess}
            handleClose={hideEditModificatoriaDialog}
          ></EditModificatoria>
        )}
      </Dialog>

      {/* DIALOG/MODAL PARA BORRAR MODIFICATORIA */}

      <Dialog
        visible={deleteModificatoriaDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirmar"
        modal
        footer={deleteModificatoriaDialogFooter}
        onHide={hideDeleteRowDialog}
      >
        <div className="confirmation-content d-flex align-items-center ">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {nameDocumento && (
            <span>
              ¿Estas seguro que deseas eliminar la modificatoria{" "}
              <b>{nameDocumento}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* DIALOG/MODAL PARA BORRAR DOCUMENTO ORIGINAL */}

      <Dialog
        visible={deleteDocOriginalDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirmar"
        modal
        footer={deleteDocOriginalDialogFooter}
        onHide={hideDeleteRowDialog}
      >
        <div className="confirmation-content d-flex align-items-center ">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {nameDocumento && (
            <span>
              ¿Estas seguro que deseas eliminar el análisis normativo de{" "}
              <b>{nameDocumento}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}

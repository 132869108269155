import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import React, { Component } from 'react';
import axiosBaseURL from './httpCommon';
import ShareButton from './ShareButton';

export default class Buttons extends Component{
    
    constructor(props){
        super(props);
        this.state = {

            baseUrl:  window.location.href,
        };
        
       // this.searchForDownload= this.searchForDownload.bind(this);
    }
    
    /*searchForDownload(e){
    
        e.preventDefault()
        
        let url = this.state.baseUrl+'/api/searchid/'+this.props.doc_id
        console.log(url)
        
        axios.get(url)
        .then(response => {
            let b64internal = 'data:application/pdf;base64,'+response._source.data
            console.log(b64internal)
            this.setState({
                b64data: b64internal
            })
        })
        .catch(error =>{
          console.log("searchId failed");
        });
    }*/
 
    
    
    render(){
        let b64 = 'data:application/pdf;base64,'
        //console.log(b64)
        let name = this.props.name + '.pdf'
        let printFunc = (e)=>{

            axiosBaseURL.get('/api/searchid/'+this.props.doc_id)
            .then(response => {
                //console.log(response.data._source.data);
                b64 = 'data:application/pdf;base64,'+response.data._source.pdf_base64;
                let pdfWindow = window.open("")
                pdfWindow.document.write("<iframe width='100%' height='100%' src='"+encodeURI(b64) + "'></iframe>")
                //this.setState({base64data: response.data._source.data});
            }).catch(error =>{
                console.log(error);
            });           

        }

        let downloadFunc = () => {

            axiosBaseURL.get('/api/searchid/'+this.props.doc_id)
            .then(response => {
                //console.log(response.data._source.pdf_base64);

                const linkSource = 'data:application/pdf;base64,'+response.data._source.pdf_base64;
                const downloadLink = document.createElement("a");
                const fileName = this.props.name;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                //this.setState({base64data: response.data._source.data});
            }).catch(error =>{
                console.log(error);
            });  
            
        }
        
        return(
            <div>
                <div className="buttonsSpacing">
                        <IconButton aria-label="download" size="small" onClick={(e) => {e.stopPropagation(); downloadFunc(e)}}>
                            <GetAppIcon fontSize="small" />
                        </IconButton>
                </div>
                <div className="buttonsSpacing">
                    <IconButton aria-label="print" size="small" onClick={(e) => {e.stopPropagation(); printFunc(e)}}>
                        <PrintIcon fontSize="small"/>
                    </IconButton>
                </div>
                <div className="buttonsSpacing">
                    <ShareButton doc_id={this.props.doc_id}/>
                </div>
            </div>
        );
    }
    
}

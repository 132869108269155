import { Col, Row } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import Admin from "../Admin/Admin";
import DetalleWrapper from "../Detalle/DetalleWrapper";
import ListaResultadosAvanzada from "../ListaResultados/ListaResultadosAvanzada";
import ListaResultadosWrapper from "../ListaResultados/ListaResultadosWrapper";

const AppBody = (props) => {

  return (
    <Row className="spacing1 nomargin">
      <Col sm={1} md={1} lg={1}></Col>
      <Col sm={10} md={10} lg={10}>
        <div className="resultList">
          <Switch>
            <Route
              exact
              path="/searchDetail/:id"
              component={(props) => (
                <DetalleWrapper key={window.location.pathname}></DetalleWrapper>
              )}
            ></Route>
            <Route
              path="/searchAll/:text/:size/:start"
              component={(props) => (
                <ListaResultadosWrapper
                  input={props.match.params.text}
                  key={window.location.pathname}
                ></ListaResultadosWrapper>
              )}
            ></Route>
              <Route
              path="/searchAdvanced/"
              component={(props) => {
                  const searchParams = new URLSearchParams(props.location.search);
                  const years = searchParams.getAll('years');
                  const text = searchParams.get('text');
                  const dependencies = searchParams.getAll('dependencies');
                  const number = searchParams.get('number');
                  const size = searchParams.get('size');
                  const start = searchParams.get('start');

                  return (
                    <ListaResultadosAvanzada
                      input={text}
                      size={size}
                      start={start} 
                      years={years.length === 0 ? undefined : years}
                      dependencies={dependencies.length === 0 ? undefined : dependencies}
                      number={number}
                      key={window.location.pathname}
                    ></ListaResultadosAvanzada>
                  )
              }}
            ></Route>
              <Route
              path="/admin"
              render={() => {
                if (!props.isAuthenticated) {
                  props.handleAdminAuthentication();
                }
                return <Admin {...props} />;
              }}
            ></Route>
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default AppBody;

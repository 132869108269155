import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axiosBaseURL from "../httpCommon";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import Modal from "react-bootstrap/Modal";
import ClickAndPreview2 from "../ClickAndPreview2";
import documentService from "../../services/documents";
import { Tag } from "primereact/tag";
import { addLocale, locale} from "primereact/api";
import dateService from "../../services/dates";

locale('es');
addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  today: 'Hoy',
  clear: 'Limpiar',
  dateFormat: 'dd/mm/yy'
  //...
});

const EditModificatoria = ({
  data,
  handleCloseDialogEdit,
  handleClose,
  modificatoria,
}) => {
  const [formValues, setFormValues] = useState({
    iddocoriginal: modificatoria ? modificatoria.docoriginal.id : null,
    idmodificatoria: modificatoria ? modificatoria.id : null,
    relacioninversa: null,
    relacion: null,
    idtextovigente: null,
    base64textovigente: null,
    fecharelacion: null,
  });
  const [tipoRelaciones, setTipoRelaciones] = useState([
    { relacion: "", inversa: "" },
  ]);
  const [selectedTipoRelacion, setSelectedTipoRelacion] = useState(null);
  const [selectedFechaRelacion, setSelectedFechaRelacion] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [flagB64, setFlagB64] = useState(false);
  const [tagsModificatoria, setTagsModificatoria] = useState(null);
  const [b64, setB64] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    loadRelaciones();
    loadModificatoria();
  }, []);

  const loadRelaciones = async () => {
    try {
      const response = await axiosBaseURL.get("/api/gettiporelaciones");
      const tipoRelacionesResult = response.data.hits.hits.map((obj) => ({
        relacion: obj.fields.nombre[0],
        inversa: obj.fields.inversa[0],
      }));
      setTipoRelaciones(tipoRelacionesResult);
    } catch (error) {
      console.log(error);
    }
  };

  const loadModificatoria = async () => {
    try {
      documentService
        .getDocumentById(modificatoria.id)
        .then((modificatoria) => {
          const newFormValues = {
            ...formValues,
            relacion: modificatoria.relacionados[0].tipo_relacion,
            relacioninversa: "Es actualizada por",
            idtextovigente: modificatoria.texto_vigente,
          };
          setFormValues(newFormValues);
          setTagsModificatoria(modificatoria);
          loadPdfTextoVigente(modificatoria.texto_vigente);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const loadPdfTextoVigente = (id) => {
    try {
      documentService.getPdfDocumentById(id).then((base64) => {
        setFlagB64(true);
        setB64(base64);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target.result.substring(
        e.target.result.indexOf(",") + 1
      );
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        base64textovigente: fileData,
      }));
      setB64(fileData);
      setIsChanged(true);
      setFlagB64(true);
    };
    reader.readAsDataURL(file);
  };

  // SUBMIT DE LA INFO

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("SUBMIT", formValues);
    const dataSinModificar = {
      iddocoriginal: null,
      idmodificatoria: null,
      relacioninversa: null,
      relacion: null,
      idtextovigente: null,
      base64textovigente: null,
      fecharelacion: null,
    };

    if (isChanged) {
      axiosBaseURL
        .post(`/api/modifyrelacion`, formValues)
        .then((response) => {
          handleCloseDialogEdit(false);
        })
        .catch((error) => {
          alert("Error al guardar la información: " + error);
        });
    } else {
      axiosBaseURL
        .post(`/api/modifyrelacion`, dataSinModificar)
        .then((response) => {
          handleCloseDialogEdit(false);
        })
        .catch((error) => {
          alert("Error al guardar la información: " + error);
        });
    }
  };

  const handleTipoRelacion = (value) => {
    setIsChanged(true);
    setSelectedTipoRelacion(value);
    if (value[0]) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        relacion: value[0].relacion,
        relacioninversa: value[0].inversa,
      }));
    }
  };

  const handleCalendar = (value) => {
    setIsChanged(true);
    setSelectedFechaRelacion(value);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      fecharelacion: value,
    }));
  };

  return (
    <div className="ml-5">
      {/* DOCUMENTO ORIGINAL */}
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modalConfirmRelacion">
          <p className="d-flex justify-content-center">
            La relación se guardó correctamente
          </p>
          <button
            className="ui button saveButton d-flex justify-content-center"
            style={{ margin: "auto", marginBottom: "20px" }}
            onClick={() => setOpenModal(false)}
          >
            Aceptar
          </button>
        </div>
      </Modal>
      <Form onSubmit={handleSubmit}>
        {/* MODIFICATORIAS */}

        <Form.Group
          as={Row}
          className="d-flex align-items-center"
          controlId="formHorizontalPassword"
        >
          <Form.Label column sm={12}>
            <h3 className="font-weight-bold">Modificatoria</h3>
          </Form.Label>

          <Col sm={12} className="py-3">
            <div className="d-flex align-items-center pb-4">
              {tagsModificatoria ? (
                <>
                  <p className="font-italic mb-0 font-weight-bold">
                    {modificatoria.modificatoria}
                  </p>
                  <div className=" ml-4">
                    <Tag className="bg-secondary mr-2">
                      {tagsModificatoria.tipo_documento}
                    </Tag>
                    <Tag className="bg-secondary mr-2">
                      {tagsModificatoria.anio}
                    </Tag>
                    <Tag className="bg-secondary mr-2">
                      {tagsModificatoria.numero}
                    </Tag>{" "}
                  </div>
                </>
              ) : (
                <p className="">Cargando documento...</p>
              )}
            </div>
          </Col>
          <div className="d-flex justify-content-start align-items-center">
            <Col className="pr-0">
              <MultiSelect
                disabled
                value={selectedTipoRelacion}
                placeholder={formValues.relacion}
                onChange={(e) => handleTipoRelacion(e.value)}
                options={tipoRelaciones}
                optionLabel="relacion"
                filter
                maxSelectedLabels={3}
                selectionLimit={1}
                style={{ width: "175px" }}
                className="md:w-20rem"
              />
            </Col>

            <Col className="">
              <div>
                <Calendar
                  placeholder={dateService.formatDate(modificatoria.fechamodificatoria)}
                  value={selectedFechaRelacion}
                  onChange={(e) => handleCalendar(e.target.value)}
                  dateFormat="dd/mm/yy"
                />
              </div>
            </Col>
          </div>
        </Form.Group>

        {/*END  MODIFICATORIAS */}

        {/* TEXTO VIGENTE */}

        <Form.Group
          as={Row}
          className="d-flex align-items-center mt-5 pb-3"
          controlId="formTextoVigente"
        >
          <Form.Label column sm={12}>
            <h3 className="font-weight-bold">Texto Vigente</h3>
          </Form.Label>
          <Col sm={3}>
            <p>Ver Texto Vigente actual</p>
          </Col>
          <Col sm={1} className="d-flex mb-3">
            {flagB64 && (
              <div className="containerPdfTextoVigente">
                <div className="modalPreviewPDFRelacion">
                  <ClickAndPreview2 base64={b64} />
                </div>
              </div>
            )}
          </Col>
          <Col sm={12} className="py-3 pb-4">
            <h3>
              Si desea cambiar el texto vigente existente seleccione uno nuevo
            </h3>
          </Col>
          <Col sm={2}>
            <input
              type="file"
              accept=".pdf"
              className="pt-3 inputTextoVigente"
              onChange={handleFileChange}
            />
          </Col>
        </Form.Group>

        {/* BOTONES */}

        <Form.Group as={Row} className="my-5" controlId="formFile">
          <Col sm={{ span: 10, offset: 0 }}>
            {data && (
              <>
                <button
                  className="ui button mr-3 saveButton"
                  type="submit"
                  disabled={isChanged === false}
                >
                  Guardar
                </button>
                <button
                  className="ui button"
                  type="cancel"
                  onClick={handleClose}
                >
                  Cancelar
                </button>
              </>
            )}
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditModificatoria;
